import { useSphere } from "@react-three/cannon";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { isDesktop } from "react-device-detect";
import * as THREE from "three";
import useStore from "../store";

const SPEED = 20;
const keys = {
  KeyW: "forward",
  KeyS: "backward",
  KeyA: "left",
  KeyD: "right",
  Space: "jump",
};
const moveFieldByKey = (key) => keys[key];
const direction = new THREE.Vector3();
const frontVector = new THREE.Vector3();
const sideVector = new THREE.Vector3();
const rotation = new THREE.Vector3();
const speed = new THREE.Vector3();

const usePlayerControls = () => {
  const movement = useStore((state) => state.movement);
  const setMovement = useStore((state) => state.actions.setMovement);
  // const [movement, setMovement] = useState({
  //   forward: false,
  //   backward: false,
  //   left: false,
  //   right: false,
  //   jump: false,
  // });
  useEffect(() => {
    const handleKeyDown = (e) =>
      setMovement({ ...movement, [moveFieldByKey(e.code)]: true });
    const handleKeyUp = (e) =>
      setMovement({ ...movement, [moveFieldByKey(e.code)]: false });

    const handleMouse = (event) => {
      if (isDesktop) {
        if (event?.deltaY < 0) {
          setMovement({ ...movement, forward: true, backward: false });
          setTimeout(() => {
            setMovement({ ...movement, forward: false, backward: false });
          }, 500);
        } else {
          setMovement({ ...movement, forward: false, backward: true });
          setTimeout(() => {
            setMovement({ ...movement, forward: false, backward: false });
          }, 500);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("wheel", handleMouse);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("wheel", handleMouse);
    };
  }, []);
  return movement;
};

export const Player = (props) => {
  const [ref, api] = useSphere(() => ({
    mass: 1,
    type: "Dynamic",
    position: [0, 10, 0],
  }));
  const { forward, backward, left, right, jump, rotateRight, rotateLeft } =
    usePlayerControls();
  const { camera } = useThree();
  const velocity = useRef([0, 0, 0]);
  useEffect(() => api.velocity.subscribe((v) => (velocity.current = v)), []);
  useFrame((state) => {
    ref.current.getWorldPosition(camera.position);
    frontVector.set(0, 0, Number(backward) - Number(forward));
    sideVector.set(Number(left) - Number(right), 0, 0);
    direction
      .subVectors(frontVector, sideVector)
      .normalize()
      .multiplyScalar(SPEED)
      .applyEuler(camera.rotation);
    speed.fromArray(velocity.current);
    camera.position.y = 7;
    if (rotateRight) {
      camera.rotation.y -= 0.01;
    }
    if (rotateLeft) {
      camera.rotation.y += 0.01;
    }
    // api.velocity.set(0, 7, 0);
    api.velocity.set(direction.x, velocity.current[1], direction.z);
    // if (jump && Math.abs(velocity.current[1].toFixed(2)) < 0.05)
    //   api.velocity.set(velocity.current[0], 10, velocity.current[2]);
  });
  return <mesh ref={ref} />;
};

// mcraft player

// import * as THREE from "three";
// import { useEffect, useRef, useState } from "react";
// import { useSphere } from "@react-three/cannon";
// import { useThree, useFrame } from "@react-three/fiber";

// const SPEED = 5;
// const keys = {
//   KeyW: "forward",
//   KeyS: "backward",
//   KeyA: "left",
//   KeyD: "right",
//   Space: "jump",
// };
// const moveFieldByKey = (key) => keys[key];
// const direction = new THREE.Vector3();
// const frontVector = new THREE.Vector3();
// const sideVector = new THREE.Vector3();
// const rotation = new THREE.Vector3();
// const speed = new THREE.Vector3();

// const usePlayerControls = () => {
//   const [movement, setMovement] = useState({
//     forward: false,
//     backward: false,
//     left: false,
//     right: false,
//     jump: false,
//   });
//   useEffect(() => {
//     const handleKeyDown = (e) =>
//       setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: true }));
//     const handleKeyUp = (e) =>
//       setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: false }));
//     document.addEventListener("keydown", handleKeyDown);
//     document.addEventListener("keyup", handleKeyUp);
//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//       document.removeEventListener("keyup", handleKeyUp);
//     };
//   }, []);
//   return movement;
// };

// export const Player = (props) => {
//   const axe = useRef();
//   const [ref, api] = useSphere(() => ({
//     mass: 1,
//     type: "Dynamic",
//     position: [0, 50, 0],
//     ...props,
//   }));
//   const { forward, backward, left, right, jump } = usePlayerControls();
//   const { camera } = useThree();
//   const velocity = useRef([0, 0, 0]);
//   useEffect(() => api.velocity.subscribe((v) => (velocity.current = v)), []);
//   useFrame((state) => {
//     ref.current.getWorldPosition(camera.position);
//     frontVector.set(0, 0, Number(backward) - Number(forward));
//     sideVector.set(Number(left) - Number(right), 0, 0);
//     direction
//       .subVectors(frontVector, sideVector)
//       .normalize()
//       .multiplyScalar(SPEED)
//       .applyEuler(camera.rotation);
//     speed.fromArray(velocity.current);
//     axe.current.rotation.x = THREE.MathUtils.lerp(
//       axe.current.rotation.x,
//       Math.sin((speed.length() > 1) * state.clock.elapsedTime * 10) / 6,
//       0.1
//     );
//     axe.current.rotation.copy(camera.rotation);
//     axe.current.position
//       .copy(camera.position)
//       .add(camera.getWorldDirection(rotation).multiplyScalar(1));
//     api.velocity.set(direction.x, velocity.current[1], direction.z);
//     if (jump && Math.abs(velocity.current[1].toFixed(2)) < 0.05)
//       api.velocity.set(velocity.current[0], 10, velocity.current[2]);
//   });
//   return (
//     <>
//       <mesh ref={ref} />
//       <mesh ref={axe} onPointerMissed={(e) => (axe.current.rotation.x = -0.5)}>
//         <boxBufferGeometry args={[0.01, 0.01]} />
//         <meshPhysicalMaterial color="green" />
//       </mesh>
//     </>
//   );
// };
