import React from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useFullscreen, useToggle } from "react-use";
import useStore from "../store";
function Overlay() {
  const ref = React.useRef(null);

  const [ready, setReady] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  //fullscreen
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(ref, show, {
    onClose: () => toggle(false),
  });
  const isPointerLocked = useStore((state) => state.isPointerLocked);
  // console.log("isPointerLocked", isPointerLocked);
  return (
    <>
      <BrowserView>
        {!isMobile && <div className="dot" />}
        {/* {isPointerLocked && (
          <div style={{ position: "absolute", bottom: 20, right: 200 }}>
            <p
              style={{
                flex: "1 1 0%",
                fontSize: 12,
                lineHeight: "1em",
                textAlign: "right",
                color: "black",
              }}
            >
              <a style={{ color: "white" }}>Press ESC to unlock pointer</a>{" "}
            </p>
          </div>
        )} */}
        <div style={{ position: "absolute", bottom: 20, right: 40 }}>
          <p
            style={{
              flex: "1 1 0%",
              fontSize: 12,
              lineHeight: "1em",
              textAlign: "right",
              color: "black",
            }}
          >
            <a href="https://artcooly.com/" style={{ color: "white" }}>
              Designed by ArtCooly.com
            </a>{" "}
          </p>
        </div>
      </BrowserView>
      <MobileView>
        <div className="dot" />

        <div style={{ position: "absolute", bottom: 20, right: 40 }}>
          <p
            style={{
              flex: "1 1 0%",
              fontSize: 12,
              lineHeight: "1em",
              textAlign: "right",
              color: "black",
            }}
          >
            <a href="https://artcooly.com/" style={{ color: "white" }}>
              Designed by ArtCooly.com
            </a>{" "}
          </p>
        </div>
      </MobileView>
    </>
  );
}

export default Overlay;
