import { Text } from "@react-three/drei";
import React from "react";
import * as THREE from "three";
import { useSpring, animated } from "@react-spring/three";
import useStore from "../store";

const ExitProp = () => {
  const exit = useStore((state) => state.exit);
  const toggleExit = useStore((state) => state.actions.toggleExit);

  const [scroll, setScroll] = useSpring(() => ({
    loop: false,
    reverse: false,

    from: { positionz: -6 },

    onStart: () => {
      if (exit) {
        toggleExit(false);
      }
    },
    config: {
      duration: 2000,
    },
  }));
  React.useEffect(() => {
    if (exit) {
      setScroll({ positionz: 1.8, delay: 5000 });
    }
  }, [exit]);
  const fontMat = React.useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: "yellow",
        metalness: 1,
        roughness: 0,
        transparent: false,
      }),
    []
  );
  const fontMat2 = React.useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: "yellow",
        metalness: 1,
        roughness: 0,
      }),
    []
  );
  const handleClick = () => window.open("https://artcooly.com");
  return (
    <animated.group
      //   position={[0, 1.8, 20]}
      position-y={scroll.positionz}
      rotation={[0, 0, 0]}
      position-z={20}
      castShadow
      onClick={() => {
        handleClick();
      }}
    >
      <mesh castShadow>
        <boxBufferGeometry args={[10, 10, 2]} />
        <meshPhysicalMaterial color={"black"} clearcoat />
      </mesh>
      <mesh position={[-1.5, -0.2, 0]} rotation={[0, 0, 0]}>
        <Text
          rotation={[0, Math.PI, 0]}
          position={[1.5, 2.5, -1.1]}
          fontSize={1}
          color="yellow"
          material={fontMat}
        >
          ArtCooly.com
        </Text>
        <Text
          rotation={[0, Math.PI, 0]}
          position={[1.5, 1.5, -1.1]}
          fontSize={0.5}
          color="white"
          //   material={fontMat2}
        >
          Click here to navigate
        </Text>
      </mesh>
    </animated.group>
  );
};

export default ExitProp;
