import axios from "axios";
import React from "react";
import useStore from "../store";
import useGetArtist from "./useGetArtistName";

const getHeightWidth = (dimensions) => {
  const dim = dimensions.split(" x ");

  const height = parseFloat(dim[0]);
  const width = parseFloat(dim[1]);

  if (height > 200 || width > 200) {
    return {
      height: height / 4,
      width: width / 4,
      actualHeight: height / 4,
      actualWidth: width / 4,
    };
  }
  if (height > 150 || width > 150) {
    return {
      height: height / 2,
      width: width / 2,
      actualHeight: height / 2,
      actualWidth: width / 2,
    };
  }

  if (height === 120 && width < 100) {
    return {
      height: height / 1.3,
      width: width / 1.3,
      actualHeight: height / 1.3,
      actualWidth: width / 1.3,
    };
  }


  // const aspect = height / width;
  // // console.log("aspect", aspect, dimensions, height, width);
  // // const dime = [
  // //   "100x80", // 1.25
  // //   "100x100", // 1
  // //   "80x60", // 1.25
  // //   "60x60", //1
  // //   "60x40", //1.25
  // //   "90x30", //3
  // //   "30x90",  //0.33
  // // ];
  // if (aspect === 1) {
  //   return {
  //     height: 60, //frame height
  //     width: 60, //frame width
  //     actualHeight: height, //image height
  //     actualWidth: width, //image width
  //   };
  // }
  // if (aspect < 1) {
  //   return {
  //     height: height, //frame height
  //     width: width, //frame width
  //     actualHeight: height, //image height
  //     actualWidth: width, //image width
  //   };
  // }
  // if (aspect > 1) {
  //   return {
  //     height: height,
  //     width: width,
  //     actualHeight: height * 0.9,
  //     actualWidth: width * 0.9,
  //   };
  // }

  // return {
  //   height: height,
  //   width: width,
  //   actualHeight: height,
  //   actualWidth: width,
  // };
  // if (aspect < 1 || aspect > 1.3) {
  // } else {
  //   return {
  //     height: 50,
  //     width: 50,
  //     actualHeight: height,
  //     actualWidth: width,
  //   };
  // }
  return {
    height: height,
    width: width,
    actualHeight: height,
    actualWidth: width,
  };
  // switch (aspect) {

  //   // case height >= 80 || width >= 80:
  //   //   return {
  //   //     height: height,
  //   //     width: width,
  //   //     actualHeight: height,
  //   //     actualWidth: width,
  //   //   };
  //   case aspect === 3:
  //     console.log("case 0");
  //     return {
  //       height: height,
  //       width: width,
  //       actualHeight: height,
  //       actualWidth: width,
  //     };
  //   // case height > 80:
  //   //   return {
  //   //     height: 120,
  //   //     width: 120,
  //   //     actualHeight: height,
  //   //     actualWidth: width,
  //   //   };

  //   default:
  //     return {
  //       height: 50,
  //       width: 50,
  //       actualHeight: height,
  //       actualWidth: width,
  //     };
  // }
};
const useGetImages = () => {
  const images = useStore((state) => state.images);
  const setImages = useStore((state) => state.actions.setImages);
  const [isLoading, setIsLoading] = React.useState(false);

  const artist = useGetArtist();
  const handleGetImages = async () => {
    setIsLoading(true);

    const api = await axios
      .get(
        // `https://api.pexels.com/v1/curated?page=1&per_page=20`,
        `https://artcooly.com/getArtistArtworkDetails.php?artistname=${artist}`,
        // `https://artcooly.com/testSQLStmtMrg.php?artistname=${artist || `fritz bleichert`
        // }`
      )
      .then((res) => res.data)
      .then((res) => {
        return res;
      })
      .catch(() => {
        setIsLoading(false);
      });

    // const api = jsondata;
    const size = await Promise.all(
      api.map(async (res) => {
        // let result = await probe(res.src.large2x);
        return {
          ...res,
          sizeData: getHeightWidth(res.heightXwidth),
        };
      })
    );
    setIsLoading(false);
    if (size) setImages(size);
  };
  React.useEffect(() => {
    handleGetImages();
  }, []);
  return { images, isLoading, handleGetImages };
};
export default useGetImages;
