import { Html, useTexture } from "@react-three/drei";
import React from "react";
import * as THREE from "three";
import useStore from "../store";
import SingleImageFrame from "./SingleImageFrame";

const angle = Math.PI / 2;
const ImagePostions = {
  0: {
    position: [8.7, 10, 15],
    rotation: [0, angle * 3, 0],
  },
  1: {
    position: [-8.7, 10, -15],
    rotation: [0, angle, 0],
  },
  2: {
    position: [-27, 8, -39.7],
    rotation: [0, 0, 0],
  },
  3: {
    position: [-49.7, 8, -25],
    rotation: [0, angle, 0],
  },

  4: {
    position: [-49.7, 8, -9],
    rotation: [0, angle, 0],
  },
  5: {
    position: [-49.7, 8, 8],
    rotation: [0, angle, 0],
  },
  6: {
    position: [-49.7, 8, 25],
    rotation: [0, angle, 0],
  },
  7: {
    position: [-22.9, 10, 15],
    rotation: [0, angle * 3, 0],
  },
  8: {
    position: [-9.7, 10, -15],
    rotation: [0, angle * 3, 0],
  },
  9: {
    position: [-8, 8, -39.7],
    rotation: [0, 0, 0],
  },
  10: {
    position: [8, 8, -39.7],
    rotation: [0, 0, 0],
  },
  11: {
    position: [27, 8, -39.7],
    rotation: [0, 0, 0],
  },
  12: {
    position: [49.7, 8, -25],
    rotation: [0, angle * 3, 0],
  },
  13: {
    position: [49.7, 8, -9],
    rotation: [0, angle * 3, 0],
  },
  14: {
    position: [22.9, 10, -15],
    rotation: [0, angle, 0],
  },
  15: {
    position: [49.7, 8, 8],
    rotation: [0, angle * 3, 0],
  },
  16: {
    position: [49.7, 8, 25],
    rotation: [0, angle * 3, 0],
  },
  17: {
    position: [9.7, 10, 15],
    rotation: [0, angle, 0],
  },
  18: {
    position: [22, 10, -15],
    rotation: [0, angle * 3, 0],
  },
  19: {
    position: [-22, 10, 15],
    rotation: [0, angle, 0],
  },
  // 14: {
  //   position: [5, 8, 0],
  //   rotation: [0, 0, 0],
  // },
  // 15: {
  //   position: [5, 4, 0],
  //   rotation: [0, 0, 0],
  // },
};

// const ImagePostions = {
//   0: {
// position: [-49.7, 8, 25],
// rotation: [0, angle, 0],
//   },
//   1: {
// position: [-49.7, 8, 8],
// rotation: [0, angle, 0],
//   },
//   2: {
// position: [-49.7, 8, -9],
// rotation: [0, angle, 0],
//   },
//   3: {
//     position: [-49.7, 8, -25],
//     rotation: [0, angle, 0],
//   },

//   4: {
// position: [-27, 8, -39.7],
// rotation: [0, 0, 0],
//   },
//   5: {
// position: [-8, 8, -39.7],
// rotation: [0, 0, 0],
//   },
//   6: {
// position: [8, 8, -39.7],
// rotation: [0, 0, 0],
//   },
//   7: {
// position: [27, 8, -39.7],
// rotation: [0, 0, 0],
//   },
//   8: {
// position: [49.7, 8, -25],
// rotation: [0, angle * 3, 0],
//   },
//   9: {
// position: [49.7, 8, -9],
// rotation: [0, angle * 3, 0],
//   },
//   10: {
// position: [49.7, 8, 8],
// rotation: [0, angle * 3, 0],
//   },
//   11: {
// position: [49.7, 8, 25],

// rotation: [0, angle * 3, 0],
//   },
//   12: {
// position: [-8.7, 10, -15],
// rotation: [0, angle, 0],
//   },
//   13: {
// position: [-22, 10, 15],
// rotation: [0, angle, 0],
//   },
//   14: {
// position: [-9.7, 10, -15],
// rotation: [0, angle * 3, 0],
//   },
//   15: {
// position: [-22.9, 10, 15],
// rotation: [0, angle * 3, 0],
//   },
//   16: {
// position: [22.9, 10, -15],
// rotation: [0, angle, 0],
//   },
//   17: {
//     position: [9.7, 10, 15],
//     rotation: [0, angle, 0],
//   },
//   18: {
//     position: [22, 10, -15],
//     rotation: [0, angle * 3, 0],
//   },
//   19: {
// position: [8.7, 10, 15],
// rotation: [0, angle * 3, 0],
//   },
//   // 14: {
//   //   position: [5, 8, 0],
//   //   rotation: [0, 0, 0],
//   // },
//   // 15: {
//   //   position: [5, 4, 0],
//   //   rotation: [0, 0, 0],
//   // },
// };

const wrapCoff = 0.5;

const ImagesDisplay = (props) => {
  // const images = useGetImages();
  const images = useStore((state) => state.images);
  const myRefs = React.useRef([]);
  myRefs.current = images.map(
    (element, i) => myRefs.current[i] ?? React.createRef()
  );

  const textures = [
    "/textures/frame/Wood029_1K_Color.jpg",

    "/textures/frame/Wood029_1K_Displacement.jpg",
    "/textures/frame/Wood029_1K_NormalDX.jpg",
    "/textures/frame/Wood029_1K_Roughness.jpg",
  ];

  const [texture1, texture2, texture3, texture4] = useTexture(textures);
  texture1.wrapS = texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(wrapCoff, wrapCoff);

  texture2.wrapS = texture2.wrapT = THREE.RepeatWrapping;
  texture2.repeat.set(wrapCoff, wrapCoff);

  texture3.wrapS = texture3.wrapT = THREE.RepeatWrapping;
  texture3.repeat.set(wrapCoff, wrapCoff);
  const toggle = useStore((state) => state.actions.toggleSound);
  const togglePointerLocker = useStore(
    (state) => state.actions.togglePointerLocker
  );

  // const inc = useStore((state) => state.inc);
  const handleClick = (data) => {
    // inc(data);
    togglePointerLocker(false);

    toggle(data);
  };

  const myimages = images.sort(
    (a, b) => parseInt(a.imageDisplayOrder) - parseInt(b.imageDisplayOrder)
  );
  // const spotLight = React.useRef();

  // useHelper(spotLight, SpotLightHelper, 0.5, "hotpink");
  // console.log("imagesRef", myRefs);
  return myimages.length > 0 ? (
    <group position={[0, 0, 0]} {...props}>
      {myimages.map((im, i) => {
        const {
          // src: { large2x: regular },
          sizeData: { width, height, actualHeight, actualWidth },
          imageUrl,
          imageFrameType: frameType,
        } = im;

        const index = parseInt(im.imageDisplayOrder);
        // const index = parseInt(i);

        const aspect = actualWidth / actualHeight;
        // const imageHeight =
        //   aspect <= 1 ? actualHeight * aspect * 0.09 : actualHeight * 0.07;
        // const imageWidth =
        //   aspect <= 1 ? actualWidth * aspect * 0.1 : actualWidth * 0.07;

        const imageHeight = actualHeight * 0.08;
        const imageWidth = actualWidth * 0.08;

        // const imageHeight =
        //   actualHeight < height
        //     ? actualHeight * 0.1
        //     : actualHeight * (actualHeight / height) * 0.05;
        // const imageWidth =
        //   actualWidth < width
        //     ? actualWidth * 0.1
        //     : actualWidth * (actualWidth / width) * 0.05;
        const size =
          im.heightXwidth
            .split("x")
            .map((a) => parseInt(a))
            .join(" x ") + " cm";
        const imageData = {
          ...im,
          size: size,
          imgTitle: im.title,
        };
        return (
          ImagePostions[index] && (
            <>
              <SingleImageFrame
                {...imageData}
                // url={regular}
                url={imageUrl}
                key={JSON.stringify(i * 1 * 1)}
                ref={myRefs.current[index]}
                frameIndex={JSON.stringify(i)}
                width={width * 0.1}
                index={index}
                height={height * 0.1}
                actualHeight={imageHeight}
                actualWidth={imageWidth}
                position={[0, 1, i]}
                dimesions={im.heightXwidth}
                // frameMaterial={frameMaterial}
                frameTextures={[texture1, texture2, texture3, texture4]}
                {...ImagePostions[index]}
                frameType={frameType}
                onClick={() => handleClick(im)}
              />
            </>
          )
        );
      })}
    </group>
  ) : (
    <group />
  );
};

export default ImagesDisplay;
