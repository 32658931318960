// import Overlay from "./components/Overlay";
// import OverlayButtons from "./components/OverlayButtons";

// import { BrowserRouter, Route, Link, Switch, Redirect } from "react-router-dom";
// import "./App.css";
// import MainRoute from "./Pages/MainRoute";
// import Reorder from "./Pages/Reorder";

// function App() {
//   return (
//     <>
//       <Route
//         exact
//         path="/"
//         render={(props) => <Redirect to="/fritz bleichert" />}
//       />
//       <Route exact path="/:artist" component={MainRoute} />
//       <Route exact path="/imagereorder/:artist" component={Reorder} />
//     </>
//   );
// }

// export default App;

import { Redirect, Route } from "react-router-dom";
import "./App.css";
import MainRoute from "./Pages/MainRoute";
import Reorder from "./Pages/Reorder";
import SnackbarProvider from "react-simple-snackbar";
function App() {
  // console.log("current path", location, history);
  return (
    <SnackbarProvider>
      <>
        <Route
          exact
          path="/"
          render={(props) => <Redirect to="/fritz bleichert" />}
        />
        <Route exact path="/:artist" component={MainRoute} />
        <Route
          exact
          path="/configurevirtualgallery/:artist"
          component={Reorder}
        />
      </>
    </SnackbarProvider>
  );
}

export default App;
