import create from "zustand";

const [useStore] = create((set) => {
  return {
    images: [],
    sound: undefined,
    useDefaultApi: true,
    isPointerLocked: true,
    exit: false,
    movement: {
      forward: false,
      backward: false,
      left: false,
      right: false,
      jump: false,
      rotateLeft: false,
      rotateRight: false,
      customKey: undefined,
    },
    actions: {
      toggleSound(sound) {
        set({ sound: sound });
      },
      setMovement(m) {
        set({ movement: m });
      },
      togglePointerLocker(pointer) {
        // console.log("isPointerLockedtoggle", pointer);
        set({ isPointerLocked: pointer });
      },
      toggleExit(pointer) {
        set({ exit: pointer });
      },
      setImages(data) {
        set({ images: data || [] });
      },
    },
  };
});

export default useStore;
// export { audio, playAudio }
