import { useCompoundBody, usePlane } from "@react-three/cannon";
import React from "react";

const x = 0;
const z = -4.8;
const defaultargs = [100, 15, 0.5];
const defaultargs2 = [80, 15, 0.5];
const defaultargs3 = [15, 15, 2];
const propsargs = [3, 8, 3];

const { PI } = Math;
const angle = PI / 2;
const compoundStructure = [
  {
    position: [0, 8, -35],
    rotation: [0, 0, 0],
    args: defaultargs,
    isSide: false,
  },
  {
    position: [45, 8, -0],
    rotation: [0, angle, 0],
    args: defaultargs2,
    isSide: true,
  },
  {
    position: [-0, 8, 35],
    rotation: [0, 0, 0],
    args: defaultargs,
    isSide: false,
  },
  {
    position: [-45, 8, -0],
    rotation: [0, angle, 0],
    args: defaultargs2,
    isSide: true,
  },
  {
    position: [-9, 8, -15],
    rotation: [0, angle, 0],
    args: defaultargs3,
    isSide: true,
  },
  {
    position: [-22.45, 8, 15],
    rotation: [0, angle, 0],
    args: defaultargs3,
    isSide: true,
  },
  {
    position: [22.45, 8, -15],
    rotation: [0, angle, 0],
    args: defaultargs3,
    isSide: true,
  },
  {
    position: [9, 8, 15],
    rotation: [0, angle, 0],
    args: defaultargs3,
    isSide: true,
  },

  {
    position: [32, 3.7, 20],
    rotation: [0, 0, 0],
    args: propsargs,
    isSide: true,
  },
  {
    position: [-32, 3.7, -20],
    rotation: [0, 0, 0],
    args: propsargs,
    isSide: true,
  },
];

function CompoundObj({ rotation, position, material: Mat }) {
  const [ref] = useCompoundBody(() => ({
    position,
    rotation,
    mass: 12,
    shapes: compoundStructure.map((i) => ({ ...i, type: "Box" })),
    type: "Static",
  }));
  return (
    <group ref={ref}>
      {compoundStructure.map(({ position: p, args, rotation: r }, i) => (
        <mesh position={p} rotation={r} key={i * 1 * 1}>
          <boxBufferGeometry args={args} />
          <meshPhysicalMaterial
            opacity={0}
            refractionRatio={0}
            transparent={true}
          />
          {/* <meshPhysicalMaterial opacity={1} color={"red"} refractionRatio={0} /> */}
        </mesh>
      ))}
    </group>
  );
}

const Floor = (props) => {
  const [ref] = usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0] }));

  return (
    <mesh ref={ref}>
      <boxBufferGeometry args={[80, 100]} />
      <meshStandardMaterial opacity={0} transparent={true} />
    </mesh>
  );
};

const ModelCompound = () => {
  return (
    <group position={[0, 1, 0]}>
      <Floor />
      <CompoundObj position={[0, 0, -30]} />
    </group>
  );
};

export default ModelCompound;
