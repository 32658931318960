//artcooly logo
import { animated, useSpring } from "@react-spring/three";
import { Text, useGLTF } from "@react-three/drei";
import React from "react";
import * as THREE from "three";
import useCubeEnv from "../../hooks/useCubeEnv";
import useGetArtistName from "../../hooks/useGetArtistName";

const angle = Math.PI / 2;

const defaultArgs = [2.5, 3.5, 2.5];

const propsArray = [
  {
    position: [-20, 3.7, 32],
    args: defaultArgs,
    rotation: [0, angle, 0],
  },
  {
    position: [20, 3.7, -32],
    args: defaultArgs,
    rotation: [0, 0, 0],
  },
];

const Logo = ({ position, rotation, ...props }) => {
  const gltf = useGLTF("/Models/logo/logoglb.glb");
  gltf.scene.children.forEach((mesh, i) => {
    mesh.castShadow = true;
    mesh.recieveShadow = true;
  });
  gltf.castShadow = true;
  gltf.recieveShadow = true;

  gltf.scene.castShadow = true;
  const copiedScene = React.useMemo(() => gltf.scene.clone(), [gltf.scene]);

  copiedScene.castShadow = true;
  copiedScene.recieveShadow = true;
  return (
    <primitive object={copiedScene} position={position} rotation={rotation} />
  );
};

export const Props = ({ ...props }) => {
  const [texture, Bind] = useCubeEnv(256);
  const material = React.useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: "#1c1c1c",
        // envMap: texture,
        metalness: 1,
        clearcoat: 1,
        roughness: 0.2,
        clearcoatRoughness: "0.2",
      }),
    []
  );
  const bottomMaterial = React.useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: "#424242",
        // envMap: texture,
        metalness: 1,
        clearcoat: 1,
        roughness: 0.5,
        // clearcoatRoughness: '0.2',
      }),
    []
  );
  const bottomDefaultArgs = [2.7, 0.7, 2.7];
  const artist = useGetArtistName();
  return (
    <group {...props}>
      {propsArray.map((i, j) => (
        <group position={i.position} key={j * 1 * 1} rotation={i.rotation}>
          <group
            position={[0, 1.5, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 3]}
            scale={2}
          >
            <Logo />
          </group>
          <TextBtn />

          <mesh castShadow material={material} position={[0, -1.5, 0]}>
            <boxBufferGeometry args={i.args} />
          </mesh>
          <mesh castShadow material={bottomMaterial} position={[0, -3, 0]}>
            <boxBufferGeometry args={bottomDefaultArgs} />
          </mesh>
        </group>
      ))}
    </group>
  );
};

const TextBtn = () => {
  const fontMat = React.useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: "yellow",
        metalness: 1,
        roughness: 0,
      }),
    []
  );
  const [{ scale }, api] = useSpring(() => ({
    scale: 1,
    opacity: 0,

    config: { mass: 5, tension: 350, friction: 40 },
  }));
  const onMove = React.useCallback((e) => api({ scale: 1.2 }), []);
  const onOut = React.useCallback(() => api({ scale: 1 }), []);
  const handleClick = (e) => {
    e.stopPropagation();

    window.open("https://artcooly.com");
  };
  return (
    <animated.group
      onClick={handleClick}
      scale={scale}
      onPointerOver={onMove}
      onPointerOut={onOut}
    >
      <mesh position={[-1.4, -0.2, 0]} rotation={[0, angle, 0]}>
        <boxBufferGeometry args={[2, 0.5, 0.2]} />
        <meshBasicMaterial color="#eee" />
      </mesh>
      <mesh position={[-1.5, -0.2, 0]} rotation={[0, angle, 0]}>
        <Text
          rotation={[0, angle * 2, 0]}
          position={[0, 0, -0.15]}
          fontSize={0.3}
          color="yellow"
          material={fontMat}
        >
          ArtCooly.com
        </Text>
      </mesh>
    </animated.group>
  );
};
export default Props;
