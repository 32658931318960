import React from "react";

const useIsPortrait = () => {
  const [isPortrait, setIsPortrait] = React.useState(true);
  var mql = window.matchMedia("(orientation: portrait)");

  // if (mql.matches) {
  //   // Portrait orientation
  // } else {
  //   // Landscape orientation
  // }

  mql.addListener(function (m) {
    if (m.matches) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);

      // Changed to landscape
    }
  });

  return isPortrait;
};

export default useIsPortrait;
