import React, { useMemo } from "react";
import { DoubleSide } from "three";

import * as THREE from "three";
const { PI } = Math;
const PlaneSize = [80, 100];

const Rotation = [-PI * 0.5, 0, 0];

const Floor = (props) => {
  let geometry = useMemo(
    () => new THREE.BoxBufferGeometry(PlaneSize[0], PlaneSize[1]),
    []
  );

  return (
    <>
      <mesh
        castShadow
        receiveShadow
        geometry={geometry}
        rotation={Rotation}
        position={[0, 28, 0]}
      >
        <meshPhysicalMaterial
          color="white"
          attach="material"
          side={DoubleSide}
          emissive={"white"}
          emissiveIntensity={0.3}
          clearcoat
          clearcoatRoughness={0.1}
        />
      </mesh>
    </>
  );
};

export default Floor;
