import React from "react";
import useGetArtistName from "../hooks/useGetArtistName";
import useGetImages from "../hooks/useGetImages";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import axios from "axios";
import { useSnackbar } from "react-simple-snackbar";
import DiagramModal from "../components/Modals/DiagramModal";
import FrameModal from "../components/Modals/FrameModal";
import { Card, Navbar, Container, Button } from "react-bootstrap";
import { isEqual } from "lodash";
const getFrameStyle = (type) => {
  switch (type) {
    case "0":
      return "#99520c";

    case "1":
      return "#f4c430";

    case "2":
      return "#eee";
    case "3":
      return "#424242";

    default:
      return "#eee";
  }
};
const Handle = SortableHandle(({ tabIndex }) => (
  <div
    style={{
      position: "relative",
      top: 0,
      left: -10,
      height: 40,
      width: 40,
      // background: "yellow",
      border: "1px solid #444",

      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "-moz-grab",
    }}
    tabIndex={tabIndex}
  >
    <i className="ti-hand-drag" style={{ fontSize: 25 }} />
  </div>
));
const SortableItem = SortableElement((props) => {
  const { value: item, imgindex, onOpenFrame } = props;
  // console.log(props);

  return (
    <Card
      style={{
        margin: 10,
        width: 260,
        background: "white",
      }}
    >
      <Card.Header>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {props.shouldUseDragHandle && <Handle />}

          <div
            style={{
              position: "relative",
              top: 0,
              left: -10,
              height: 40,
              width: 40,
              // background: "yellow",
              border: "1px solid #444",

              borderRadius: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "-moz-grab",
            }}
            onClick={() => onOpenFrame({ ...item, imgindex })}
          >
            <i
              className="ti-layout-width-default-alt"
              style={{ fontSize: 25 }}
            />
          </div>

          <div>#{imgindex + 1}</div>
        </div>
      </Card.Header>
      <Card.Body>
        <div
          className="frame"
          style={{
            height: "auto",
            width: "auto",
            borderColor: getFrameStyle(item.imageFrameType),
          }}
        >
          <img
            src={item.imageUrl}
            style={{ height: "200px", width: "200px", objectFit: "contain" }}
          />
        </div>
      </Card.Body>
    </Card>
  );
});
const SortableList = SortableContainer((props) => {
  const { items, onOpenFrame, ...restProps } = props;
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      {items.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          value={item}
          {...restProps}
          index={index}
          imgindex={index}
          onOpenFrame={onOpenFrame}
        />
      ))}
    </div>
  );
});
const Reorder = () => {
  const artist = useGetArtistName();
  const { images: myimages, handleGetImages } = useGetImages();
  const [images, setImages] = React.useState([]);

  const [showDiagram, setShowDiagram] = React.useState();
  const handleOpenDiagram = () => setShowDiagram(true);
  const handlecloseDiagram = () => setShowDiagram();

  const [showFrameModal, setShowFrameModal] = React.useState();
  const handleOpenFrameModal = (data) => setShowFrameModal(data);
  const handlecloseFrameModal = () => setShowFrameModal();
  const handleChangeFrame = (frameType) => {
    const arrayvalue = [...images];
    // arrayvalue.unshift({
    //   ...images[showFrameModal.imgindex],
    //   imageFrameType: frameType,
    // });
    arrayvalue[showFrameModal.imgindex] = {
      ...images[showFrameModal.imgindex],
      imageFrameType: frameType,
    };
    setImages(arrayvalue);

    handlecloseFrameModal();
  };
  const options = {
    position: "bottom-right",
    style: {
      backgroundColor: "green",
      border: "2px solid lightgreen",
      color: "white",
      fontFamily: "Roboto",
      fontSize: "20px",
      textAlign: "center",
      borderRadius: 5,
    },
    closeStyle: {
      color: "white",
      fontSize: "16px",
    },
  };

  // Usage with hooks
  const [openSnackbar] = useSnackbar(options);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImages(arrayMove(images, oldIndex, newIndex));
  };

  const onSubmit = async () => {
    const newImages = images.map((d, i) => {
      return {
        entityId: d.entityId,
        orderValue: i,
        imageFrameType: d.imageFrameType,
      };
    });
    // console.log("reordered", newImages);

    await axios({
      url: "https://artcooly.com/updateImageOrder.php",
      method: "put",
      data: newImages,
    })
      .then((res) => {
        // console.log("response", res);

        openSnackbar("Image reorder successful ");
        handleGetImages();
      })
      .catch((err) => console.log("error", err));
  };
  React.useEffect(() => {
    setImages(
      myimages.sort(
        (a, b) => parseInt(a.imageDisplayOrder) - parseInt(b.imageDisplayOrder)
      )
    );
  }, [myimages]);

  const sortedImages = myimages.sort(
    (a, b) => parseInt(a.imageDisplayOrder) - parseInt(b.imageDisplayOrder)
  );

  const Equal = isEqual(images, sortedImages);
  // console.log(
  //   "isIdent",
  //   Equal,
  //   images[0]?.imageFrameType,
  //   sortedImages[0]?.imageFrameType
  // );
  return (
    <div>
      <DiagramModal open={showDiagram} onClose={handlecloseDiagram} />
      <FrameModal
        open={Boolean(showFrameModal)}
        onClose={handlecloseFrameModal}
        data={showFrameModal}
        onConfirm={(a) => handleChangeFrame(a)}
      />

      <Navbar bg="dark" variant="dark" fixed>
        <Container>
          <Navbar.Brand href="#home">Reorder Images of {artist} </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Button style={{ marginRight: 20 }} onClick={handleOpenDiagram}>
              Gallery Floorplan
            </Button>
            <Button
              onClick={onSubmit}
              // disabled={JSON.stringify(images) === JSON.stringify(gotimages)}
              disabled={Equal}
            >
              Save changes
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              onClick={() =>
                window.open(
                  `https://virtualgallery.artcooly.com/${artist}`,
                  "_blank"
                )
              }
            >
              Show changes in Virtual gallery
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <SortableList
        shouldUseDragHandle={true}
        useDragHandle
        axis="xy"
        items={images}
        onSortEnd={onSortEnd}
        onOpenFrame={handleOpenFrameModal}
      />
    </div>
  );
};

export default Reorder;

// import React from 'react'
// import useGetImages from '../hooks/useGetImages';

// const Reorder = () => {
//     const images = useGetImages();
//     return (
//         <div style={{height:'100vh',width:'100wv'}}>
// <div style={{height:'7vh',backgroundColor:'white',boxShadow:'0px 5px 10px rgba(0,0,0,0.3)'}}>
//     <div style={{}}>
//         Reorder Images
//     </div>

// </div>
//         <div className="reorderContainer">

//             {images.map((im, i) => {
//                 return (
//                     <div className="container" key={i}>
//                         <div className="imageContent" >
//                             <img src={im.imageUrl} />
//                         </div>
//                         <div>
//                             <h3>Title: {im.title}</h3>
//                             <p>Image Display Order : {im.imageDisplayOrder}</p>
//                             <p>Artist Name : {im.artistName}</p>
//                         </div>
//                     </div>
//                 )
//             })}
//         </div>
//         </div>

//     )
// }

// export default Reorder
