import React, { useMemo } from "react";

import * as THREE from "three";

const defaultargs = [30, 10, 0.5];

const { PI } = Math;
const angle = PI / 2;

const compoundStructure = [
  {
    position: [-15, 12, -22.45],
    rotation: [0, 0, 0],
    args: defaultargs,
  },
  {
    position: [-15, 12, 9.2],
    rotation: [0, 0, 0],
    args: defaultargs,
  },
  {
    position: [15, 12, -9.2],
    rotation: [0, 0, 0],
    args: defaultargs,
  },
  {
    position: [15, 12, 22.45],
    rotation: [0, 0, 0],
    args: defaultargs,
  },
  // {
  //   position: [0, 10, 16],
  //   rotation: [0, 0, 0],
  //   args: defaultargs2,
  // },
];

function CenterWalls(props) {
  const wallType1 = useMemo(() => new THREE.BoxBufferGeometry(14, 18, 0.8), []);

  const red = useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        clearcoat: 1,

        roughness: 0.2,
      }),
    []
  );

  const ropeGeo = useMemo(
    () => new THREE.BoxBufferGeometry(0.1, 0.1, 10, 32),
    []
  );
  const ropeMat = useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: "#474747",
        metalness: 1,
        roughness: 0,
      }),
    []
  );

  return (
    <group>
      {compoundStructure.map(({ position: p, rotation: r, isSide }, i) => {
        return (
          <group key={JSON.stringify(i * 1 * 1)}>
            <mesh
              castShadow
              receiveShadow
              position={[p[0], p[1], p[2]]}
              rotation={r}
              key={i}
              geometry={wallType1}
              material={red}
            />
            <mesh
              position={[p[0] + 4, p[1] + 10, p[2]]}
              rotation={[angle, 0, 0]}
              castShadow
              geometry={ropeGeo}
              material={ropeMat}
            />

            <mesh
              position={[p[0] - 4, p[1] + 10, p[2]]}
              rotation={[angle, 0, 0]}
              castShadow
              geometry={ropeGeo}
              material={ropeMat}
            />
          </group>
        );
      })}
    </group>
  );
}

export default CenterWalls;
