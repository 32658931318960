import React from "react";
import { useHistory, useParams } from "react-router-dom";

import Main from "./Main";
import { useToggle } from "react-use";
import { isDesktop, isMobile } from "react-device-detect";
import OverlayButtons from "../components/OverlayButtons";
import Overlay from "../components/Overlay";
import Loader from "../components/Loader";
import useGetImages from "../hooks/useGetImages";
import useIsPortrait from "../hooks/useIsPortrait";
import useFullScreen from "../hooks/useFullScreen";

const MainRoute = () => {
  const history = useHistory();
  const [show, toggle] = useToggle(false);
  const ref = React.useRef(null);

  // const isFullscreen = useFullscreen(ref, show, {
  //   onClose: () => toggle(false),
  // });
  // console.log("from route", params);
  // if (!artist) {
  //   history.push("/fritz-bleichert");
  // }

  const { images, isLoading } = useGetImages();
  const isPortrait = useIsPortrait();
  const { isFullscreen, requestFullScreen, closeFullScreen } = useFullScreen();
  const isIos = !!navigator.platform.match(/iPhone|iPod|iPad/);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {isDesktop ? (
        <>
          <Loader />
          <Main />
          <Overlay />
          <OverlayButtons toggleFullScreen={() => closeFullScreen()} />
        </>
      ) : (
        <>
          {isPortrait ? (
            <>
              <CenterDiv btnTitle="Please enable screen rotation and rotate your device" />
            </>
          ) : isIos ? (
            <>
              <>
                <Loader />
                <Main />
                <Overlay />
                <OverlayButtons
                  toggleFullScreen={() => closeFullScreen()}
                  isIos={isIos}
                />
              </>
            </>
          ) : (
            <>
              {!isFullscreen ? (
                <CenterDiv
                  btnTitle="Click here for fullscreen"
                  onClick={() => requestFullScreen()}
                />
              ) : (
                <>
                  <Loader />
                  <Main />
                  <Overlay />
                  <OverlayButtons toggleFullScreen={() => closeFullScreen()} />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainRoute;

const CenterDiv = ({ btnTitle, onClick }) => {
  return (
    <div className="enterFullScreenContainer">
      <button className="enter-button" onClick={() => onClick()}>
        {btnTitle}
      </button>
    </div>
  );
};

// import React from "react";
// import { useHistory, useParams } from "react-router-dom";
// import Main from "./Main";
// import { useFullscreen, useToggle } from "react-use";
// import DeviceOrientation, { Orientation } from "react-screen-orientation";

// const MainRoute = () => {
// const history = useHistory();
// // console.log("from route", params);
// const { artist } = useParams();
// if (!artist) {
//   history.push("/fritz-bleichert");
// }
// const ref = React.useRef(null);

//   //fullscreen
//   const [show, toggle] = useToggle(false);
//   const isFullscreen = useFullscreen(ref, show, {
//     onClose: () => toggle(false),
//   });
//   return (
//     <>
//       {isMobile ? (
//         <DeviceOrientation lockOrientation={"landscape"}>
//           {/* Will only be in DOM in landscape */}
//           <Orientation orientation="landscape" alwaysRender={false}>
//             <div ref={ref} className="fullScreenContainer">
//               {isFullscreen ? (
//                 <>
//                   <Main />
//                   <div style={{ position: "absolute", top: 20, right: 20 }}>
//                     <button
//                       style={{
//                         all: "unset",

//                         borderRadius: "200px",
//                         border: "2px solid black",
//                         height: 30,
//                         width: 30,
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                       }}
//                       onClick={() => toggle()}
//                     >
//                       <i
//                         className="fa fa-close"
//                         style={{ fontSize: 20, color: "black", padding: 5 }}
//                       ></i>
//                     </button>
//                   </div>
//                 </>
//               ) : (
//                 <div className="enterFullScreenContainer">
//                   <button className="enter-button" onClick={() => toggle()}>
//                     Please click here for fullscreen
//                   </button>
//                 </div>
//               )}
//             </div>
//           </Orientation>
//           {/* Will stay in DOM, but is only visible in portrait */}
//           <Orientation orientation="portrait">
//             <div
//               style={{
//                 background: "#333",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 height: "100vh",
//               }}
//             >
//               <p
//                 style={{
//                   color: "white",
//                   fontSize: 20,
//                 }}
//               >
//                 Please Rotate your device
//               </p>
//             </div>
//           </Orientation>
//         </DeviceOrientation>
//       ) : (
//         <Main />
//       )}
//     </>
//   );
// };

// export default MainRoute;
