import React from "react";
import { Button, Modal } from "react-bootstrap";

const DiagramModal = ({ open, onClose }) => {
  return (
    <Modal size="xl" show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Virtual Gallery Diagram</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src="/images/diagram.jpg"
          style={{
            height: 500,
            width: "100%",
            objectFit: "contain",
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DiagramModal;
