import { animated, useSpring } from "@react-spring/three";
import { a } from "@react-spring/web";
import { Html, useTexture, useCursor } from "@react-three/drei";
import { Box, Flex } from "@react-three/flex";
import React from "react";

const angle = Math.PI / 2;
const FramesArray = [
  {
    // position: [1, 1, 0],
    rotation: [angle, angle, 0],
    type: "height",
    posType: "left",
  },
  {
    // position: [2, 1, 0],
    rotation: [angle, angle, 0],
    type: "height",
    posType: "right",
  },
  {
    // position: [0, 0.2, 6],
    // position: [1.5, 0, 0],

    rotation: [angle, 0, 0],
    type: "width",
    posType: "bottom",
  },
  {
    // position: [6.2, 0.2, -0.1],
    // position: [1.5, 2, 0],

    rotation: [angle, 0, 0],
    type: "width",
    posType: "top",
  },
];

const getPostion = (posType, height, width) => {
  switch (posType) {
    case "left":
      return [-width / 2 - 0.05, 0, 0];
      break;
    case "right":
      return [width / 2 + 0.05, 0, 0];
      break;
    case "bottom":
      return [0, -height / 2 - 0.05, 0];
      break;

    case "top":
      return [0, height / 2 + 0.05, 0];
      break;

    default:
      return [0, 0, 0];
      break;
  }
};

const SingleImageFrame = ({
  url,
  position,
  rotation,
  width,
  height,
  actualHeight,
  actualWidth,
  frameTextures,
  frameIndex,
  frameType,
  dimesions,
  title,
  onClick,
  index,
  ...imageData
}) => {
  // console.log(
  //   "hehe",
  //   title,
  //   dimesions,
  //   width,
  //   height,
  //   actualWidth,
  //   actualHeight
  // );
  const framesProperies = [
    {
      metalness: 0.5,
      roughness: 1,
      map: frameTextures[0],
      normalMap: frameTextures[2],
      roughnessMap: frameTextures[3],
      bumpMap: frameTextures[0],
      bumpScale: 0.5,
    },
    {
      metalness: 1,
      roughness: 0,
      color: "#FFD700",
      emissive: "#FFD700",
      emissiveIntensity: 0.1,
    },
    {
      metalness: 1,
      roughness: 0,
    },

    {
      clearCoat: true,
      color: "#424242",
    },
  ];

  const [hover, setHovered] = React.useState(false);

  const texture = useTexture(url);
  const imageRef = React.useRef();

  const [{ scale }, api] = useSpring(() => ({
    scale: 1,
    opacity: 0,

    config: { mass: 5, tension: 350, friction: 40 },
  }));
  const scaleSpring = useSpring({ x: hover ? 1.1 : 1 });
  // const onMove = React.useCallback((e) => api({ scale: 1.2 }), []);
  // const onOut = React.useCallback(() => api({ scale: 1 }), []);
  // const spotLight = React.useRef();
  // useHelper(spotLight, PointLightHelper, 0.5, "hotpink");
  const handleClick = React.useCallback((e) => {
    e.stopPropagation();
    onClick();
  }, []);
  useCursor(hover);

  const getWidth = (width) => {
    const newWidth = parseInt(width)
    // console.log("title", title, newWidth, actualWidth, width)
    if (newWidth <= 3)
      return width * 1.2

    else if (newWidth === 4)
      return width / 1.2

    else if (newWidth === 5)
      return width / 1.3

    else if (newWidth === 6)
      return width / 1.5

    else if (newWidth === 7)
      return width / 1.6

    else if (newWidth === 8)
      return width / 1.8

    else if (newWidth === 9)
      return width / 1.9

    else if (newWidth === 10)
      return width / 2

    else if (newWidth === 11)
      return width / 2.1

    else if (newWidth === 12)
      return width / 2.2
    else
      return width
  }

  // console.log(title, getWidth(width), actualWidth, width)
  return (
    <>
      <animated.group
        position={position}
        // position-z={pos}
        rotation={rotation}
        scale={scaleSpring.x}
        castShadow
        receiveShadow
        onClick={handleClick}
        // onPointerOver={onMove}
        // onPointerOut={onOut}
        onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
        onPointerOut={(e) => setHovered(false)}
      // onPointerDown={handleClick}
      >
        {/* <Html
          as="div" // Wrapping element (default: 'div')
          // Project content behind the canvas (default: false)
          center
          distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
          transform // If true, applies matrix3d transformations (default=false)
        >
          <div
            className="infoMainContainer"
          >
            <div className="infoTitle">{index + 1}</div>
          </div>
        </Html> */}
        <Html
          as="div" // Wrapping element (default: 'div')
          // Project content behind the canvas (default: false)
          center
          distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
          zIndexRange={[1, 0]} // Z-order range (default=[16777271, 0])
          transform // If true, applies matrix3d transformations (default=false)
          sprite // Renders as sprite, but only in transform mode (default=false)
        >
          <a.div
            className="infoMainContainer"
            style={{
              opacity: scaleSpring.x.to({ range: [1, 1.1], output: [0, 1] }),
              transform: `translateX(${getWidth(width) * 100}px)`,
            }}
          >
            <h5 className="artTitle">{imageData.imgTitle}</h5>
            <hr className="hr" />
            <table className="infoContainer" style={{ width: "100%" }}>
              <tbody>
                <RenderInfoCard
                  title={"Artist Name"}
                  value={imageData.artistName}
                />
                <RenderInfoCard
                  title={"Artwork Topic"}
                  value={imageData.artworkTopics}
                />
                <RenderInfoCard title={"Size"} value={imageData.size} />

                <RenderInfoCard
                  title={"Materials"}
                  value={imageData.usedMaterials}
                />
                <RenderInfoCard
                  title={"Price"}
                  value={`${parseInt(imageData.value)} ${imageData.currency}`}
                />
              </tbody>
            </table>
            <hr className="hr" />
            <div className="infoTitle">Click on the image for more info</div>
          </a.div>
        </Html>
        <Flex justifyContent="center" alignItems="center">
          <Box centerAnchor>
            <animated.mesh position={[0, 0, 0.1]} ref={imageRef}>
              <planeBufferGeometry
                attach="geometry"
                args={[actualWidth, actualHeight]}
              />
              <meshBasicMaterial
                attach="material"
                map={texture}
                toneMapped={true}
              />
            </animated.mesh>
            <mesh>
              <boxBufferGeometry args={[width, height, 0]} />
              <meshStandardMaterial
                color="white"
                emissive="white"
                emissiveIntensity={2}
              />
            </mesh>
            {FramesArray.map((f, findex) => (
              <RenderFrame
                position={getPostion(f.posType, height, width)}
                {...f}
                key={JSON.stringify(findex * 1 * 1)}
                width={width}
                height={height}
                frameTextures={frameTextures}
                framesProperies={framesProperies[frameType]}
              />
            ))}
          </Box>
        </Flex>
      </animated.group>
    </>
  );
};

const RenderInfoCard = ({ title, value }) => {
  return (
    <tr className="infoItem">
      <td className="infoTitle">{title}</td>
      <td className="infoValue">{value}</td>
    </tr>
  );
};

const RenderFrame = ({ frameTextures, color, framesProperies, ...props }) => {
  return (
    <animated.mesh {...props}>
      <boxBufferGeometry args={[props[props.type] + 0.3, 0.2, 0.2]} />

      <meshPhysicalMaterial
        {...(color
          ? {
            // color: color,
            color: color,
            roughness: 1,
            metalness: 0,
            emissive: "white",
            emissiveIntensity: 2,
          }
          : {
            ...framesProperies,
          })}
      />
    </animated.mesh>
  );
};

export default SingleImageFrame;

// import { animated, useSpring } from "@react-spring/three";
// import { a } from "@react-spring/web";
// import { Html, useTexture } from "@react-three/drei";
// import { Box, Flex } from "@react-three/flex";
// import React from "react";

// const angle = Math.PI / 2;
// const FramesArray = [
//   {
//     // position: [1, 1, 0],
//     rotation: [angle, angle, 0],
//     type: "height",
//     posType: "left",
//   },
//   {
//     // position: [2, 1, 0],
//     rotation: [angle, angle, 0],
//     type: "height",
//     posType: "right",
//   },
//   {
//     // position: [0, 0.2, 6],
//     // position: [1.5, 0, 0],

//     rotation: [angle, 0, 0],
//     type: "width",
//     posType: "bottom",
//   },
//   {
//     // position: [6.2, 0.2, -0.1],
//     // position: [1.5, 2, 0],

//     rotation: [angle, 0, 0],
//     type: "width",
//     posType: "top",
//   },
// ];

// const getPostion = (posType, height, width) => {
//   switch (posType) {
//     case "left":
//       return [-width / 2 - 0.05, 0, 0];
//       break;
//     case "right":
//       return [width / 2 + 0.05, 0, 0];
//       break;
//     case "bottom":
//       return [0, -height / 2 - 0.05, 0];
//       break;

//     case "top":
//       return [0, height / 2 + 0.05, 0];
//       break;

//     default:
//       return [0, 0, 0];
//       break;
//   }
// };

// const SingleImageFrame = ({
//   url,
//   position,
//   rotation,
//   width,
//   height,
//   actualHeight,
//   actualWidth,
//   frameTextures,
//   frameIndex,
//   frameType,
//   dimesions,
//   title,
//   onClick,
//   ...imageData
// }) => {
//   // console.log(
//   //   "hehe",
//   //   title,
//   //   dimesions,
//   //   width,
//   //   height,
//   //   actualWidth,
//   //   actualHeight
//   // );
//   const framesProperies = [
//     {
//       metalness: 0.5,
//       roughness: 1,
//       map: frameTextures[0],
//       normalMap: frameTextures[2],
//       roughnessMap: frameTextures[3],
//       bumpMap: frameTextures[0],
//       bumpScale: 0.5,
//     },
//     {
//       metalness: 1,
//       roughness: 0,
//       color: "#FFD700",
//       emissive: "#FFD700",
//       emissiveIntensity: 0.1,
//     },
//     {
//       metalness: 1,
//       roughness: 0,
//     },

//     {
//       clearCoat: true,
//       color: "#424242",
//     },
//   ];

//   const [hover, set] = React.useState(null);

//   const texture = useTexture(url);
//   const imageRef = React.useRef();

//   const [{ scale }, api] = useSpring(() => ({
//     scale: 1,
//     opacity: 0,

//     config: { mass: 5, tension: 350, friction: 40 },
//   }));
// const onMove = React.useCallback((e) => api({ scale: 1.2 }), []);
// const onOut = React.useCallback(() => api({ scale: 1 }), []);
//   // const spotLight = React.useRef();
//   // useHelper(spotLight, PointLightHelper, 0.5, "hotpink");
//   const handleClick = React.useCallback((e) => {
//     e.stopPropagation();
//     onClick();
//   }, []);

//   return (
//     <>
//       <animated.group
//         position={position}
//         // position-z={pos}
//         rotation={rotation}
//         scale={scale}
//         castShadow
//         receiveShadow
//         onClick={handleClick}
//         onPointerOver={onMove}
//         onPointerOut={onOut}
//         // onPointerDown={handleClick}
//       >
//         <Html
//           as="div" // Wrapping element (default: 'div')
//           // Project content behind the canvas (default: false)
//           center
//           distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
//           zIndexRange={[1, 0]} // Z-order range (default=[16777271, 0])
//           transform // If true, applies matrix3d transformations (default=false)
//           sprite // Renders as sprite, but only in transform mode (default=false)
//         >
//           <a.div
//             className="infoMainContainer"
//             style={{
//               opacity: scale.to({ range: [1, 1.05], output: [0, 1] }),
//               transform: `translate(${(width / 2) * 150}px)`,
//             }}
//           >
//             <h2 className="artTitle">{imageData.imgTitle}</h2>
//             <hr className="hr" />
//             <table className="infoContainer" style={{ width: "100%" }}>
//               <tbody>
//                 <RenderInfoCard
//                   title={"Artist Name"}
//                   value={imageData.artistName}
//                 />
//                 <RenderInfoCard
//                   title={"Artwork Topic"}
//                   value={imageData.artworkTopics}
//                 />
//                 <RenderInfoCard title={"Size"} value={imageData.size} />

//                 <RenderInfoCard
//                   title={"Materials"}
//                   value={imageData.usedMaterials}
//                 />
//                 <RenderInfoCard
//                   title={"Price"}
//                   value={`${parseInt(imageData.value)} ${imageData.currency}`}
//                 />
//               </tbody>
//             </table>
//             <hr className="hr" />
//             <div className="infoTitle">Click on the image for more info</div>
//           </a.div>
//         </Html>
//         <Flex justifyContent="center" alignItems="center">
//           <Box centerAnchor>
//             <animated.mesh position={[0, 0, 0.1]} ref={imageRef}>
//               <planeBufferGeometry
//                 attach="geometry"
//                 args={[actualWidth, actualHeight]}
//               />
//               <meshBasicMaterial
//                 attach="material"
//                 map={texture}
//                 toneMapped={true}
//               />
//             </animated.mesh>
//             <mesh>
//               <boxBufferGeometry args={[width, height, 0]} />
//               <meshStandardMaterial
//                 color="white"
//                 emissive="white"
//                 emissiveIntensity={2}
//               />
//             </mesh>
//             {FramesArray.map((f, findex) => (
//               <RenderFrame
//                 position={getPostion(f.posType, height, width)}
//                 {...f}
//                 key={JSON.stringify(findex * 1 * 1)}
//                 width={width}
//                 height={height}
//                 frameTextures={frameTextures}
//                 color={hover ? "white" : undefined}
//                 framesProperies={framesProperies[frameType]}
//               />
//             ))}
//           </Box>
//         </Flex>
//       </animated.group>
//     </>
//   );
// };

// const RenderInfoCard = ({ title, value }) => {
//   return (
//     <tr className="infoItem">
//       <td className="infoTitle">{title}</td>
//       <td className="infoValue">{value}</td>
//     </tr>
//   );
// };

// const RenderFrame = ({ frameTextures, color, framesProperies, ...props }) => {
//   return (
//     <animated.mesh {...props}>
//       <boxBufferGeometry args={[props[props.type] + 0.3, 0.2, 0.2]} />

//       <meshPhysicalMaterial
//         {...(color
//           ? {
//               // color: color,
//               color: color,
//               roughness: 1,
//               metalness: 0,
//               emissive: "white",
//               emissiveIntensity: 2,
//             }
//           : {
//               ...framesProperies,
//             })}
//         // metalness={0.5}
//         // map={frameTextures[0]}
//         // color={color}
//         // // displacementMap={frameTextures[1]}
//         // normalMap={frameTextures[2]}
//         // roughnessMap={frameTextures[3]}
//         // bumpMap={frameTextures[0]}
//         // bumpScale={0.5}
//       />
//       {/* <meshPhysicalMaterial
//         metalness={0.5}
//         map={frameTextures[0]}
//         color={color}
//         // displacementMap={frameTextures[1]}
//         normalMap={frameTextures[2]}
//         roughnessMap={frameTextures[3]}
//         bumpMap={frameTextures[0]}
//         bumpScale={0.5}
//       /> */}
//     </animated.mesh>
//   );
// };

// export default SingleImageFrame;
