import { animated, config, useSpring } from "@react-spring/web";
import axios from "axios";
import React from "react";
import { Rating } from "react-simple-star-rating";
import useStore from "../store";
import { useSnackbar } from "react-simple-snackbar";
const options = {
  position: "bottom-right",
  style: {
    backgroundColor: "green",
    border: "2px solid lightgreen",
    color: "white",
    fontFamily: "Roboto",
    fontSize: "20px",
    textAlign: "center",
    borderRadius: 5,
  },
  closeStyle: {
    color: "white",
    fontSize: "16px",
  },
};
// import useImageColor from 'use-image-color'
const Sidebar = () => {
  const data = useStore((state) => state.sound);
  const toggle = useStore((state) => state.actions.toggleSound);
  const [openSnackbar] = useSnackbar(options);
  const { left } = useSpring({
    from: { left: "-100%" },
    left: data ? "0" : "-100%",
    loop: false,
    config: {
      duration: 200,
      config: config.stiff,
    },
  });
  const imageAnim = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 3000,
    loop: false,
    config: config.wobbly,
  });
  const { xyz } = useSpring({
    from: { xyz: [0, 1000, 0] },
    to: {
      xyz: [0, 0, 0],
    },
    loop: false,
    delay: 500,
    config: {
      duration: 500,
      config: config.wobbly,
    },
  });
  const [rating, setRating] = React.useState(0);
  const handleRating = (rate) => {
    setRating(rate);
    // Some logic
  };

  const size =
    data?.heightXwidth
      .split("x")
      .map((a) => parseInt(a))
      .join(" x ") + " cm";

  const handleAddToCart = async (data) => {
    await axios.get(` http://artcooly.com/getNonce.php`).then(async (res) => {
      await axios
        .post(`https://artcooly.com/wp-json/wc/store/cart/add-item`, {
          headers: {
            "X-WC-Store-API-Nonce": res.data,
            "Content-Type": "application/json",
          },
          data: {
            // id: data.entityId,
            id: 7378,
            quantity: 1,
          },
        })
        .then((res) => {
          openSnackbar("Product added to cart");
          console.log("res", res);
        })
        .catch((err) => {});
    });
  };

  // const { colors } = useImageColor(data?.imageUrl, { cors: true, colors: 5 })
  const ImageDisplay = ({ src }) => {
    return (
      <>
        <animated.div
          className="ImageContainer"
          style={{
            transform: xyz.to(
              (x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`
            ),
          }}
        >
          <div className="frame">
            <animated.img
              src={data?.imageUrl}
              // onLoad={() => set(!flip)}
              style={{ opacity: imageAnim.opacity }}
            />
          </div>
        </animated.div>
        <animated.div className="content">
          <button className="close-btn" onClick={() => toggle()}>
            X
          </button>
          <h1 className="title">{data?.title}</h1>
          <h6 className="sub-title">- {data?.artistName}</h6>
          <animated.div className="table-container">
            <table className="table">
              <tbody>
                <tr>
                  <td>Artwork Topics</td>
                  <td>{data?.artworkTopics}</td>
                </tr>
                <tr>
                  <td>Materials</td>
                  <td>{data?.usedMaterials}</td>
                </tr>
                <tr>
                  <td>Size</td>
                  <td>
                    {size}{" "}
                    <small>
                      <i>(Height x Width)</i>
                    </small>
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{data?.content}</td>
                </tr>
              </tbody>
            </table>
          </animated.div>
          <animated.div className="price-container">
            <h1>
              {parseInt(data?.value)} {data?.currency}
            </h1>
            <button onClick={() => handleAddToCart(data)}>Buy Now</button>
          </animated.div>
          <div>
            <p>Please rate this art work</p>
            <Rating onClick={handleRating} ratingValue={rating} />
          </div>
        </animated.div>
      </>
    );
  };

  return (
    <animated.div style={{ left: left }} className="Sidebar">
      <ImageDisplay />
    </animated.div>
  );
};

export default Sidebar;
