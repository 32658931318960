import { useGLTF } from "@react-three/drei";
import React from "react";

const propsArray = [
  {
    position: [15, 5.5, -22.45],
    rotation: [0, 0, 0],
  },
  {
    position: [15, 5.5, 9.2],
    rotation: [0, 0, 0],
  },
  {
    position: [-15, 5.5, -9.2],
    rotation: [0, 0, 0],
  },
  {
    position: [-15, 5.5, 22.45],
    rotation: [0, 0, 0],
  },
];

const Light = ({ position, rotation, ...props }) => {
  const gltf = useGLTF("/Models/light/scene.gltf");
  // console.log("gltf", gltf);
  gltf.scene.children.forEach((mesh, i) => {
    mesh.castShadow = true;
    mesh.recieveShadow = true;
  });
  gltf.castShadow = true;
  gltf.recieveShadow = true;

  gltf.scene.castShadow = true;
  const copiedScene = React.useMemo(() => gltf.scene.clone(), [gltf.scene]);

  copiedScene.castShadow = true;
  copiedScene.recieveShadow = true;
  return (
    <primitive object={copiedScene} position={position} rotation={rotation} />
  );
};

export const HangingLights = ({ ...props }) => {
  return (
    <group {...props}>
      {propsArray.map((i, j) => (
        <group
          position={i.position}
          key={j * 1 * 1}
          rotation={i.rotation}
          scale={1}
        >
          <Light />
        </group>
      ))}
    </group>
  );
};

export default HangingLights;
