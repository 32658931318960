import React from "react";
import * as THREE from "three";
const angle = Math.PI / 2;

const defaultArgs = [27, 0.3, 0.3];
const defaultArgs2 = [10.1, 0.3, 0.3];
const FramesArray = [
  {
    position: [-0, 15.4, 0],
    args: defaultArgs2,
    rotation: [0, angle, 0],
    type: "height",
    posType: "left",
  },

  {
    position: [-0, 7, 0],
    args: defaultArgs2,
    rotation: [0, angle, 0],
    type: "height",
    posType: "left",
  },

  // {
  //   position: [-0, 0, 0],
  //   args: defaultArgs2,
  //   rotation: [0, angle, 0],
  //   type: "height",
  //   posType: "left",
  // },

  {
    position: [-0, -2.5, 0],
    args: defaultArgs2,
    rotation: [0, angle, 0],
    type: "height",
    posType: "left",
  },
  {
    position: [-0, -11.5, 0],
    args: defaultArgs2,
    rotation: [0, angle, 0],
    type: "height",
    posType: "left",
  },
  {
    // position: [0, 0.2, 6],
    position: [0, 2, -4.9],
    args: defaultArgs,

    rotation: [0, 0, angle],
    type: "width",
    posType: "bottom",
  },
  {
    // position: [6.2, 0.2, -0.1],
    position: [0, 2, 4.9],
    args: defaultArgs,

    rotation: [0, 0, angle],
    type: "width",
    posType: "top",
  },
  {
    // position: [6.2, 0.2, -0.1],
    position: [0, 2, 0],
    args: defaultArgs,

    rotation: [0, 0, angle],
    type: "width",
    posType: "top",
  },
];

const RenderFrame = ({ args, frameMat, ...props }) => {
  return (
    <mesh material={frameMat} {...props} castShadow>
      <boxBufferGeometry args={args} />
    </mesh>
  );
};

export const Window = ({
  glassArgs,
  frmaeArgs,
  glassPosition,
  frameGen,
  glassMat,
  frameMat,
  ...props
}) => {
  const frames = frameGen || FramesArray;
  return (
    <group position={[0, 0, 0]} {...props}>
      {frames.map((i, j) => (
        <RenderFrame
          key={j * 1 * 1}
          {...i}
          args={frmaeArgs || i.args}
          frameMat={frameMat}
        />
      ))}
      <mesh
        rotation={[0, angle, 0]}
        position={glassPosition || [0, 2, 0]}
        material={glassMat}
      >
        <boxBufferGeometry args={glassArgs} />
      </mesh>
    </group>
  );
};

const Windows = () => {
  const glassMat = React.useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        metalness: 0.9,
        roughness: 0.05,
        envMapIntensity: 0.9,
        clearcoat: 1,
        color: "#757575",
        // transparent: true,
        // transmission: .95,
        opacity: 1,
        reflectivity: 0.2,
        refractionRatio: 0.15,
        ior: 0.9,
        side: THREE.BackSide,
      }),
    []
  );

  const frameMat = React.useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        metalness: 1,
        roughness: 0.3,

        color: "#8a807d",
      }),
    []
  );
  return (
    <group>
      <Window
        position={[-40, 12, -42.4]}
        glassArgs={[9.5, 27, 0.2]}
        glassMat={glassMat}
        frameMat={frameMat}
      />
      <Window
        position={[-40, 12, -29.1]}
        glassArgs={[9.5, 27, 0.2]}
        glassMat={glassMat}
        frameMat={frameMat}
      />
      <Window
        position={[-40, 12, -15.8]}
        glassArgs={[9.5, 27, 0.2]}
        glassMat={glassMat}
        frameMat={frameMat}
      />
      <Window
        position={[-40, 12, 15.8]}
        glassArgs={[9.5, 27, 0.2]}
        glassMat={glassMat}
        frameMat={frameMat}
      />
      <Window
        position={[-40, 12, 29.1]}
        glassArgs={[9.5, 27, 0.2]}
        glassMat={glassMat}
        frameMat={frameMat}
      />
      <Window
        position={[-40, 12, 42.4]}
        glassArgs={[9.5, 27, 0.2]}
        glassMat={glassMat}
        frameMat={frameMat}
      />
    </group>
  );
};

export default Windows;
