import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { ACESFilmicToneMapping, sRGBEncoding } from "three";
import Light from "../components/Light";
import Environ from "../components/Environ";
import { Physics } from "@react-three/cannon";
import { Player } from "../components/Player";

import { Stats, PointerLockControls } from "@react-three/drei";

import ModelCompound from "../components/ModelCompound";

// import ArtGallery from "../components/Scene";

import Room from "../components/Room/index";

import useStore from "../store";
import ExitProp from "../components/ExitProp";
import { isMobile } from "react-device-detect";
var userAgent = window.navigator.userAgent;
const isIphone = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
const Main = () => {
  const pointerRef = React.useRef();

  const movement = useStore((state) => state.movement.customKey);
  const isPointerLocked = useStore((state) => state.isPointerLocked);
  const togglePointerLocker = useStore(
    (state) => state.actions.togglePointerLocker
  );

  React.useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        pointerRef?.current?.unlock();
      }, 100);
    }
  }, [movement]);

  React.useEffect(() => {
    if (pointerRef.current && !isMobile) {
      if (isPointerLocked) {
        // setTimeout(() => {
        //   pointerRef.current.lock();
        // }, 100);
      } else {
        setTimeout(() => {
          // pointerRef.current.unlock();
          document.exitPointerLock();
          togglePointerLocker(false);
        }, 100);
      }
    }
  }, [isPointerLocked]);
  // setInterval(() => {
  //   console.log("isPointerLocked time", pointerRef?.current);
  // }, 5000);
  return (
    <Canvas
      id="foobar"
      shadowMap
      frameloop="demand"
      mode="concurrent"
      shadows={true}
      style={{ height: "100vh", width: "100vw" }}
      colorManagement
      sRGB={true}
      autoClear={true}
      camera={{ fov: 45 }}
      performance={{
        min: 0.5,
        current: 1,
        max: 1,
        debounce: 200,
      }}
      gl={{
        outputEncoding: sRGBEncoding,
        toneMapping: ACESFilmicToneMapping,
        toneMappingExposure: 1,
        antialias: true,
        powerPreference: "high-performance",
        alpha: true,
      }}
      raycaster={{
        //important for pointer events
        //do not change
        computeOffsets: (e) => ({
          offsetX: e.target.width / 2,
          offsetY: e.target.height / 2,
        }),
        far: 30,
        near: 5,
      }}
    >
      {/* testing */}
      {/* <Camera />
      <Suspense fallback={null}>
        <Environ />
        <Light />
        <Physics gravity={[0, -30, 0]}>
          <Room />

        </Physics>
        <Stats />
      </Suspense> */}

      {/* not testing */}
      {!isMobile && (
        <PointerLockControls
          ref={pointerRef}
          selector="#foobar"
          onLock={() => {
            togglePointerLocker(true);
          }}
          // isLocked={isPointerLocked}
          // lock={() => {

          //   pointerRef.current.isLocked = true
          // }}
          // unlock={() => pointerRef.current.isLocked = false}

          // disconnect={() => alert("disconnected")}
        />
      )}

      <Suspense fallback={null}>
        <Environ />
        <ExitProp />
        <Physics gravity={[0, -30, 0]}>
          <ModelCompound />
          <Room />

          <Player />
        </Physics>

        <Light />

        {/* <Stats /> */}
      </Suspense>
    </Canvas>
  );
};
export default Main;

// import React, { Suspense } from "react";
// import { Canvas, useFrame } from "@react-three/fiber";
// // import Camera from "../components/Camera";
// import {
//   ACESFilmicToneMapping,
//   sRGBEncoding,
//   DirectionalLightHelper,
// } from "three";
// import Light from "../components/Light";
// import Environ from "../components/Environ";
// import GuiControls from "../components/GuiControls";
// import { Physics, useBox, useCompoundBody } from "@react-three/cannon";
// import { Player } from "../components/Player";
// import { withControls, Controls } from "react-three-gui";

// import { Stats, PointerLockControls } from "@react-three/drei";

// import ModelCompound from "../components/ModelCompound";
// import ImagesDisplay from "../components/ImagesDisplay";
// import Loader from "../components/Loader";
// import Overlay from "../components/OverlayButtons";
// // import ArtGallery from "../components/Scene";

// import Room from "../components/Room/index";
// import { Ground, Cube } from "../components/minecft/Cube";

// const Main = () => {
//   const YourCanvas = withControls(Canvas);

//   // return (
//   //   <Canvas
//   //     shadows
//   //     gl={{ alpha: false }}
//   //     camera={{ fov: 45 }}
//   //     raycaster={{
//   //       computeOffsets: (e) => ({
//   //         offsetX: e.target.width / 2,
//   //         offsetY: e.target.height / 2,
//   //       }),
//   //     }}
//   //   >
//   //     <ambientLight intensity={0.3} />
//   //     <pointLight castShadow intensity={0.8} position={[100, 100, 100]} />
//   //     <Physics gravity={[0, -30, 0]}>
//   //       <Ground />
//   //       <Player />
//   //       <Cube position={[0, 0.5, -10]} />
//   //     </Physics>
//   //     <PointerLockControls />
//   //   </Canvas>
//   // );

//   return (
//     <Canvas
//       shadows
//       gl={{ alpha: false }}
//       camera={{ fov: 45 }}
//       raycaster={{
//         computeOffsets: (e) => ({
//           offsetX: e.target.width / 2,
//           offsetY: e.target.height / 2,
//         }),
//       }}
//     >
//       <PointerLockControls />

//       {/* <Camera /> */}
//       <Environ />
//       <Physics gravity={[0, -30, 0]}>
//         <Ground />
//         <Cube />
//         {/* <Floor /> */}
//         {/* {'it is important room'} */}

//         {/* <Cube position={[0, 1.6, -10]} />
//           <Cubes position={[0, 1.6, -10]} /> */}
//         {/* <ArtGallery position={[0, 0, 0]} rotation={[0, Math.PI, 0]} /> */}

//         <Player />
//       </Physics>
//       {/* <Box position={[0, 2, 0]} /> */}

//       <Light />
//       {/* <Room /> */}
//       {/* <Floor /> */}
//       {/* <Effects /> */}
//     </Canvas>
//   );
// };

// export default function Doha() {
//   return <Main />;
// }

// working with gui;

// import React, { Suspense } from "react";
// import { Canvas, useFrame } from "@react-three/fiber";
// // import Camera from "../components/Camera";
// import {
//   ACESFilmicToneMapping,
//   sRGBEncoding,
//   DirectionalLightHelper,
// } from "three";
// import Light from "../components/Light";
// import Environ from "../components/Environ";
// import GuiControls from "../components/GuiControls";
// import { Physics, useBox, useCompoundBody } from "@react-three/cannon";
// import { Player } from "../components/Player";
// import { withControls, Controls } from "react-three-gui";

// import { Stats, PointerLockControls } from "@react-three/drei";

// import ModelCompound from "../components/ModelCompound";
// import ImagesDisplay from "../components/ImagesDisplay";
// import Loader from "../components/Loader";
// import Overlay from "../components/OverlayButtons";
// // import ArtGallery from "../components/Scene";

// import Room from "../components/Room/index";
// import create from "zustand";

// const Main = () => {
//   const YourCanvas = withControls(Canvas);

//   return (
//     <div>
//       <Canvas
//         shadowMap
//         // frameloop="demand"
//         // mode="concurrent"
//         shadows={true}
//         style={{ height: "100vh", width: "100vw" }}
//         colorManagement
//         sRGB={true}
//         autoClear={true}
//         camera={{ fov: 45 }}
//         performance={{
//           min: 0.5,
//           current: 1,
//           max: 1,
//           debounce: 200,
//         }}
//         gl={{
//           outputEncoding: sRGBEncoding,
//           toneMapping: ACESFilmicToneMapping,
//           toneMappingExposure: 1,
//           antialias: true,
//           powerPreference: "high-performance",
//           alpha: true,
//         }}
//         raycaster={{
//           //important for pointer events
//           //do not change
//           computeOffsets: (e) => ({
//             offsetX: e.target.width / 2,
//             offsetY: e.target.height / 2,
//           }),
//         }}
//       >
//         <PointerLockControls />

//         <Suspense fallback={null}>
//           <Environ />
//           <Physics gravity={[0, -30, 0]}>
//             <Room />

//             <ModelCompound />

//             <Player />
//           </Physics>

//           <Light />

//           {/* <Stats /> */}
//         </Suspense>
//       </YourCanvas>
//       <Loader />
//     </div>
//   );
// };

// export default function Doha() {
//   return (
//     <Controls.Provider>
//       <Main />
//       <Controls />
//     </Controls.Provider>
//   );
// }
