import { Plane, useTexture } from "@react-three/drei";
import React from "react";
import { DoubleSide } from "three";
import * as THREE from "three";

const wrapCoff = [80, 80];

const ConreteFloor = () => {
  const textures = [
    "/textures/concrete/PavingStones079_1K_AmbientOcclusion.jpg",
    "/textures/concrete/PavingStones079_1K_Color.jpg",
    "/textures/concrete/PavingStones079_1K_Displacement.jpg",
    "/textures/concrete/PavingStones079_1K_NormalDX.jpg",
    "/textures/concrete/PavingStones079_1K_Roughness.jpg",
  ];
  const [texture1, texture2, texture3, texture4, texture5, texture6] =
    useTexture(textures);
  texture1.wrapS = texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture2.wrapS = texture2.wrapT = THREE.RepeatWrapping;
  texture2.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture3.wrapS = texture3.wrapT = THREE.RepeatWrapping;
  texture3.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture4.wrapS = texture4.wrapT = THREE.RepeatWrapping;
  texture4.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture5.wrapS = texture5.wrapT = THREE.RepeatWrapping;
  texture5.repeat.set(wrapCoff[0], wrapCoff[1]);
  return (
    <Plane
      args={[400, 400]}
      rotation={[Math.PI / 2, 0, 0]}
      position={[0, -0.2, 0]}
      receiveShadow
    >
      <meshPhysicalMaterial
        color="#555"
        side={DoubleSide}
        attach="material"
        aoMap={texture1}
        // displacementMap={texture3}
        map={texture2}
        roughnessMap={texture5}
        bumpMap={texture3}
        bumpScale={0.2}
        // metalness={0.8}
        // roughness={0.5}
      />
    </Plane>
  );
};

export default ConreteFloor;
