import { useGLTF } from "@react-three/drei";
import React, { Suspense } from "react";
const defaultPos = [2.4, 0, 3.1];
const flowerpots = [
  {
    position: [defaultPos[0], defaultPos[1], -defaultPos[2]],
  },
  {
    position: [-defaultPos[0], defaultPos[1], -defaultPos[2]],
  },
  {
    position: [defaultPos[0], defaultPos[1], defaultPos[2]],
  },
  {
    position: [-defaultPos[0], defaultPos[1], defaultPos[2]],
  },
];

const FlowerPots = () => {
  return (
    <Suspense fallback={null}>
      <group position={[0, 0.5, 0]} scale={15}>
        {flowerpots.map((i, j) => (
          <Model key={j * 1 * 1} position={i.position} />
        ))}
      </group>
    </Suspense>
  );
};

function Model({ ...props }) {
  const group = React.useRef();
  const { nodes, materials } = useGLTF("/Models/kundu/potted_plant_04_1k.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.potted_plant_04.geometry}
        material={materials.PottedPlant_04}
      />
    </group>
  );
}

useGLTF.preload("/Models/kundu/potted_plant_04_1k.gltf");

export default FlowerPots;
