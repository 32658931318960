import React from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
const getFrameStyle = (type) => {
  switch (type) {
    case "0":
      return "#99520c";

    case "1":
      return "#f4c430";

    case "2":
      return "#eee";

    case "3":
      return "#424242";

    default:
      return "#eee";
  }
};
const options = [
  {
    title: "Wooden",
    value: "0",
  },
  {
    title: "Gold",
    value: "1",
  },
  {
    title: "Silver",
    value: "2",
  },
  {
    title: "Black",
    value: "3",
  },
];
const FrameModal = ({ open, onClose, onConfirm, data, setImages, images }) => {
  const [selected, setSelected] = React.useState();

  React.useEffect(() => {
    if (data?.imageFrameType) {
      setSelected(data.imageFrameType);
    }
  }, [data?.imageFrameType]);
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Choose Frame</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Select
              value={selected}
              onChange={({ target: { value } }) => {
                setSelected(value);
              }}
            >
              {options.map((a) => (
                <option value={a.value}>{a.title}</option>
              ))}
            </Form.Select>
          </Col>
          <Col className="mt-3">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data && (
                <div
                  className="frame"
                  style={{
                    height: "auto",
                    width: "auto",
                    borderColor: getFrameStyle(selected),
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={data.imageUrl}
                    style={{
                      height: "200px",
                      width: "200px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => onConfirm(selected || data?.imageFrameType)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FrameModal;
