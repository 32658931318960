import React, { Suspense } from "react";
import { useTexture } from "@react-three/drei";
import * as THREE from "three";
const { PI } = Math;
const PlaneSize = [80, 100];
const Rotation = [-PI * 0.5, 0, 0];
const wrapCoff = [6, 6];
const Floor = (props) => {
  const textures = [
    "/textures/floor/1K-wood_plank_10-ao.jpg",
    "/textures/floor/1K-wood_plank_10-diffuse.jpg",
    "/textures/floor/1K-wood_plank_10-normal.jpg",
    "/textures/floor/1K-wood_plank_10-specular.jpg",
  ];
  const [texture1, texture2, texture3, texture4] = useTexture(textures);
  texture1.wrapS = texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture2.wrapS = texture2.wrapT = THREE.RepeatWrapping;
  texture2.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture3.wrapS = texture3.wrapT = THREE.RepeatWrapping;
  texture3.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture4.wrapS = texture4.wrapT = THREE.RepeatWrapping;
  texture4.repeat.set(wrapCoff[0], wrapCoff[1]);

  texture1.rotation = -(0.5 * Math.PI);

  // texture6.wrapS = texture6.wrapT = THREE.RepeatWrapping;
  // texture6.repeat.set(wrapCoff, wrapCoff);

  // useFrame(({ gl, scene }) => {
  //   // cubeCamera.current.update(gl, scene);
  // });
  // texture2.wrapS = wrapCoff;
  // texture2.wrapT = wrapCoff;
  // texture3.wrapS = wrapCoff;
  // texture3.wrapT = wrapCoff;

  return (
    <Suspense fallback={null}>
      <mesh rotation={Rotation} position={[0, 0, 0]} receiveShadow>
        <boxBufferGeometry args={PlaneSize} />
        <meshPhysicalMaterial
          color="#BA8C63"
          // displacementMap={texture3}
          aoMap={texture1}
          map={texture2}
          specularTintMap={texture4}
          bumpMap={texture3}
          bumpScale={0.2}
          // // metalness={0.8}
          // // roughness={0.5}
          clearcoat
          clearcoatRoughness={0.2}
          // envMap={floorEnv}

          // map={texture2}
          // aoMap={texture1}
          // displacementMap={texture3}
          // normalMap={texture4}
          // specularMap={texture5}
          // envMap={floorEnv}
        />
      </mesh>
    </Suspense>
  );
};

export default Floor;
