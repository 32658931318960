import { useGLTF } from "@react-three/drei";
import React, { Suspense } from "react";
const angle = Math.PI / 2;

const kundas = [
  {
    position: [-1150, 0, 1250],
    rotation: [0, -angle, 0],
  },
  {
    position: [-1150, 0, -1250],
    rotation: [0, -angle, 0],
  },
];

const Benches = () => {
  return (
    <Suspense fallback={null}>
      <group position={[0, 0.5, 0]} scale={0.03}>
        {kundas.map((i, j) => (
          <Model key={j * 1 * 1} rotation={i.rotation} position={i.position} />
        ))}
      </group>
    </Suspense>
  );
};
function Model({ ...props }) {
  const group = React.useRef();
  const { nodes, materials } = useGLTF("/Models/bench/scene.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[-Math.PI / 2, 0, 0]} scale={[102, 100, 100]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Bench_Bench001_0.geometry}
              material={materials["Bench.001"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Bench_Bench002_0.geometry}
              material={materials["Bench.002"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Bench_Bench003_0.geometry}
              material={materials["Bench.003"]}
            />
          </group>
          <group
            position={[-503.62, 590.39, -262.07]}
            rotation={[2.28, -0.65, 2.45]}
            scale={[100, 100, 100]}
          >
            <group rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group
            position={[344.28, 208.1, -161.01]}
            rotation={[-Math.PI, -0.55, 2.78]}
            scale={[100, 100, 100]}
          />
        </group>
      </group>
    </group>
  );
}

// useGLTF.preload("/scene.gltf");

export default Benches;
