import screenfull from "screenfull";
import React from "react";
const useFullScreen = () => {
  const [isFullscreen, setIsFullScreen] = React.useState(false);

  // console.log("screenfull", screenfull.isEnabled, screenfull);
  React.useEffect(() => {
    if (screenfull.isEnabled) {
      setIsFullScreen(screenfull.isFullscreen);
    }
  }, []);

  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
      setIsFullScreen(true);
    }
  };
  const closeFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.exit();
      setIsFullScreen(false);
    }
  };

  return {
    isFullscreen,
    requestFullScreen: () => toggleFullScreen(),
    closeFullScreen: () => closeFullScreen(),
  };
};

export default useFullScreen;
