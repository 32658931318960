import { animated, useSpring } from "@react-spring/three";
import React from "react";
import useStore from "../../store";
import ImagesDisplay from "../ImagesDisplay";
import Beams from "./Beams";
import Benches from "./Benches";
import Ceiling from "./Ceilling";
import CenterWalls from "./CenterWall";
import ConreteFloor from "./ConreteFloor";
import Doors from "./Door";
import Floor from "./Floor";
import HangingLights from "./HangingLights";
import FlowerPots from "./FlowerPots";
import Props from "./Props";
import Walls from "./Walls";
import Windows from "./Window";

const durationRatio = 1;
const Room = () => {
  const roomRef = React.useRef();
  const exit = useStore((state) => state.exit);
  const toggleExit = useStore((state) => state.actions.toggleExit);

  // console.log("value of exit", exit);
  // const [styles, set] = useSpring({
  // loop: false,
  // reverse: false,

  // from: { positionz: -100 },

  // // to: { scale: 1, positionz: -70, rotationY: Math.PI / 2 },

  // to: React.useCallback(async (next, cancel) => {
  //   await next({
  //     positionz: -70,
  //     delay: 4000 * durationRatio,
  //   });
  //   await next({ positionz: -30, delay: 2000 * durationRatio });
  // }, []),
  // onStart: () => {
  //   if (exit) {
  //     toggleExit(false);
  //   }
  // },
  // config: {
  //   duration: 2000 * durationRatio,
  // },
  // });
  const [scroll, setScroll] = useSpring(() => ({
    loop: false,
    reverse: false,

    from: { positionz: -100 },

    // to: { scale: 1, positionz: -70, rotationY: Math.PI / 2 },

    to: async (next, cancel) => {
      await next({
        positionz: -70,
        delay: 4000 * durationRatio,
      });
      await next({ positionz: -30, delay: 2000 * durationRatio });
    },
    onStart: () => {
      if (exit) {
        toggleExit(false);
      }
    },
    delay: 2000,
    config: {
      duration: 2000 * durationRatio,
    },
  }));
  React.useEffect(() => {
    if (exit) {
      setScroll({ positionz: -100, delay: 2500 });
    }
  }, [exit]);
  return (
    <>
      <animated.group
        position-z={scroll.positionz}
        // position-z={-30}
        rotation={[0, Math.PI / 2, 0]}
        ref={roomRef}
      >
        <ImagesDisplay rotation={[0, -Math.PI / 2, 0]} />
        <Windows />
        <Doors />
        <FlowerPots />
        <Benches />
        <HangingLights />
        <Props />
        <ConreteFloor />
        <CenterWalls />
        <Ceiling />
        <Beams />
        <Walls />
        <Floor />
      </animated.group>
    </>
  );
};

export default Room;

//old room not exporting

// import React from "react";
// import Floor from "./Floor";
// import Walls from "./Walls";
// import Ceiling from "./Ceilling";
// import Windows from "./Window";
// import Beams from "./Beams";
// import Kunda from "./Kunda";
// import Benches from "./Benches";
// import Doors from "./Door";
// import CenterWalls from "./CenterWall";
// import ConreteFloor from "./ConreteFloor";

// import Props from "./Props";
// import { useSpring, animated } from "@react-spring/three";
// import ImagesDisplay from "../ImagesDisplay";
// import { Cube } from "../minecft/Cube";

// import Box from "../Box";
// import HangingLights from "./HangingLights";
// // import Waveplane from "./WaveBannner";
// const Room = () => {
// // const { scale, positionz, rotationY } = useSpring({
// //   loop: false,
// //   // reverse: true,

// //   from: { scale: 0.8, positionz: -100, rotationY: 0 },

// //   // to: { scale: 1, positionz: -70, rotationY: Math.PI / 2 },

// //   to: React.useCallback(async (next, cancel) => {
// //     await next({
// //       rotationY: Math.PI / 2,
// //       positionz: -70,
// //       delay: 4000 * durationRatio,
// //     });
// //     await next({ positionz: -30, delay: 2000 * durationRatio });
// //   }, []),
// //   config: {
// //     duration: 2000 * durationRatio,
// //   },
// // });

// return (
//   <animated.group
//     //  position-z={positionz}
//       position-z={-30}
//       rotation={[0, Math.PI / 2, 0]}
//     >
//       <ImagesDisplay rotation={[0, -Math.PI / 2, 0]} />
//       <Windows />
//       <Doors />
//       <Kunda />
//       <Benches />
//       <HangingLights />
//       <Props />
//       <ConreteFloor />
//       <CenterWalls />
//       <Ceiling />
//       <Beams />
//       <Walls />
//       <Floor />
//       {/* <ImagesDisplay rotation={[0, -Math.PI / 2, 0]} />
//       <Windows />
//       <Doors />
//       <Kunda />
//       <Benches />
//       <HangingLights />
//       <Props />
//       <ConreteFloor />

//       <CenterWalls />
//       <Ceiling />
//       <Beams />
//       <Walls />
//       <Floor /> */}
//     </animated.group>
//   );
// };

// export default Room;
