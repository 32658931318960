import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import useStore from "../store";
import Sidebar from "./Sidebar";
const keys = {
  KeyW: "forward",
  KeyS: "backward",
  KeyA: "left",
  KeyD: "right",
  Space: "jump",
  rl: "rotateLeft",
  rr: "rotateRight",
};
const moveFieldByKey = (key) => keys[key];
function OverlayButtons({ toggleFullScreen, isIos }) {
  //fullscreen

  const movement = useStore((state) => state.movement);
  const isOpen = useStore((state) => state.sound);
  const [hovered, setHovered] = React.useState();

  const setMovement = useStore((state) => state.actions.setMovement);
  const handleKeyDown = (code) => {
    setMovement({ ...movement, [moveFieldByKey(code)]: true, customKey: code });
  };
  const handleKeyUp = (code) => {
    setMovement({
      ...movement,
      [moveFieldByKey(code)]: false,
      customKey: code + "a",
    });
  };
  const isPointerLocked = useStore((state) => state.isPointerLocked);
  return (
    <>
      <Sidebar />
      {!isOpen && (
        <div>
          <div className="moveBtnContainer">
            <RenderButton
              id="1"
              hideButton={!isMobile}
              icon="fa fa-rotate-left"
              onMouseUp={() => {
                handleKeyUp("rl");
              }}
              onMouseDown={() => {
                // alert("hello");
                handleKeyDown("rl");
              }}
              hovered={hovered}
              setHovered={setHovered}
            />
            <RenderButton
              id="2"
              hoverTitle="W"
              icon="fa fa-arrow-up"
              hideTitle={isMobile}
              onMouseUp={() => {
                handleKeyUp("KeyW");
              }}
              onMouseDown={() => {
                // alert("hello");
                handleKeyDown("KeyW");
              }}
              hovered={hovered}
              setHovered={setHovered}
            />
            <RenderButton
              id="3"
              icon="fa fa-rotate-right"
              hideButton={!isMobile}
              onMouseUp={() => {
                handleKeyUp("rr");
              }}
              onMouseDown={() => {
                handleKeyDown("rr");
              }}
              hovered={hovered}
              setHovered={setHovered}
            />
            <RenderButton
              id="4"
              hoverTitle="A"
              icon="fa fa-arrow-left"
              hideTitle={isMobile}
              onMouseUp={() => {
                handleKeyUp("KeyA");
              }}
              onMouseDown={() => {
                handleKeyDown("KeyA");
              }}
              hovered={hovered}
              setHovered={setHovered}
            />
            <RenderButton
              id="5"
              hoverTitle="S"
              icon="fa fa-arrow-down"
              hideTitle={isMobile}
              onMouseUp={() => {
                handleKeyUp("KeyS");
              }}
              onMouseDown={() => {
                handleKeyDown("KeyS");
              }}
              hovered={hovered}
              setHovered={setHovered}
            />

            <RenderButton
              id="6"
              hoverTitle="D"
              icon="fa fa-arrow-right"
              hideTitle={isMobile}
              onMouseUp={() => {
                handleKeyUp("KeyD");
              }}
              onMouseDown={() => {
                handleKeyDown("KeyD");
              }}
              hovered={hovered}
              setHovered={setHovered}
            />
            {isPointerLocked && isBrowser && (
              <p
                style={{
                  width: "100%",
                  padding: 10,
                  fontSize: 12,
                  lineHeight: "1em",
                  textAlign: "center",
                  color: "black",
                }}
              >
                <a style={{ color: "white" }}>Press ESC to unlock pointer</a>{" "}
              </p>
            )}
          </div>

          {isMobile && !isIos && (
            <div className="closeButtonContainer">
              <CloseButton toggle={() => toggleFullScreen()} />
            </div>
          )}
        </div>
      )}
    </>
  );
}
const CloseButton = ({ toggle }) => {
  return (
    <button className="closeButton" onClick={() => toggle()}>
      <i
        className="fa fa-close"
        style={{ fontSize: 20, color: "black", padding: 5 }}
      ></i>
    </button>
  );
};

const RenderButton = ({
  title,
  icon,
  onClick,
  hideButton,
  onMouseUp,
  onMouseDown,

  hovered,
  hoverTitle,
  id,
  setHovered,
}) => {
  const showData = () => {
    if (!isMobile) {
      if (hovered === id) {
        return hoverTitle;
      } else return <i className={icon}></i>;
    }
    return <i className={icon}></i>;
  };
  return (
    <div className="overlayBtnContainer">
      {!hideButton && (
        <button
          className="customButton"
          onMouseOut={() => setHovered()}
          onMouseOver={() => setHovered(id)}
          onPointerUp={(e) => onMouseUp(e, id)}
          onPointerDown={(e) => onMouseDown(e, id)}
          onTouchEnd={(e) => onMouseUp(e, id)}
        >
          {showData()}
          {/* {icon && <i className={icon}></i>} */}
        </button>
      )}
    </div>
  );
};

export default OverlayButtons;
