import { animated, useSpring } from "@react-spring/three";
import { a } from "@react-spring/web";
import { Html, Text, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React from "react";
import * as THREE from "three";
import { DoubleSide } from "three";
import useGetArtistName from "../../hooks/useGetArtistName";
import useStore from "../../store";
const angle = Math.PI / 2;

const defaultArgs = [15, 0.3, 0.3];
const defaultSideArgs = [15.5, 0.3, 0.3];

const defaultWindowArgs2 = [13.5, 0.3, 0.3];
const defaultWindowArgs3 = [8.5, 0.3, 0.3];

const defaultWindowArgs = [15.5, 0.3, 0.3];
const windowFrames = [
  {
    position: [-0, 27.4, 5.5],
    args: defaultWindowArgs,
    rotation: [0, angle, 0],
    type: "height",
    posType: "left",
  },
  {
    position: [-0, 19.5, -1.8],
    args: defaultWindowArgs,
    rotation: [0, 0, angle],
    type: "height",
    posType: "left",
  },
  {
    position: [-0, 22, 13.2],
    args: defaultWindowArgs2,
    rotation: [0, 0, angle],
    type: "height",
    posType: "left",
  },
  // {
  //   position: [-0, 22, 5.7],
  //   args: defaultWindowArgs3,
  //   rotation: [0, 0, angle],
  //   type: "height",
  //   posType: "left",
  // },
  {
    position: [-0, 22, 5.7],
    args: defaultWindowArgs,
    rotation: [0, angle, 0],
    type: "height",
    posType: "left",
  },
];
const FramesArray = [
  {
    position: [-0, 15.4, 5.5],
    args: defaultSideArgs,
    rotation: [0, angle, 0],
    type: "height",
    posType: "left",
  },

  {
    // position: [0, 0.2, 6],
    position: [0, 8, -1.8],
    args: defaultArgs,

    rotation: [0, 0, angle],
    type: "width",
    posType: "bottom",
  },
  {
    // position: [0, 0.2, 6],
    position: [0, 8, 13.2],
    args: defaultArgs,

    rotation: [0, 0, angle],
    type: "width",
    posType: "bottom",
  },
];
const frameMat = new THREE.MeshPhysicalMaterial({
  metalness: 1,
  roughness: 0.3,

  color: "#8a807d",
});
const RenderFrame = ({ args, ...props }) => {
  return (
    <mesh material={frameMat} {...props} castShadow>
      <boxBufferGeometry args={args} />
    </mesh>
  );
};
const fontMat = new THREE.MeshPhysicalMaterial({
  color: "yellow",
  metalness: 1,
  roughness: 0,
});
const TextGeo = (props) => {
  const textOptions = {
    font: "Fonts/Handlee-Regular.ttf",
    fontSize: 9,
    maxWidth: 8,
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: "center",
  };

  const artist = useGetArtistName();
  return (
    <group {...props}>
      <Text
        anchorX="center"
        anchorY="middle"
        {...textOptions}
        material={fontMat}
      >
        {artist.replace("-", " ").replace(/\b\w/g, (l) => l.toUpperCase())}
      </Text>
    </group>
  );
};

const LogoImage = (props) => {
  const imgTexture = useTexture("/logoblack.png");
  return (
    <mesh {...props}>
      <planeBufferGeometry attach="geometry" args={[90, 90]} />
      <meshPhysicalMaterial
        attach="material"
        map={imgTexture}
        transparent={true}
        side={DoubleSide}
        metalness={1}
        roughness={0}
        // toneMapped={true}
      />
    </mesh>
  );
};

const ExitSign = (props) => {
  const imgTexture = useTexture("/images/exit.png");
  return (
    <mesh {...props}>
      <planeBufferGeometry attach="geometry" args={[90, 40]} />
      <meshPhysicalMaterial
        attach="material"
        map={imgTexture}
        transparent={false}
        metalness={0}
        roughness={1}
        // toneMapped={true}
      />
    </mesh>
  );
};

const glassMat = new THREE.MeshPhysicalMaterial({
  metalness: 0.9,
  roughness: 0.05,
  envMapIntensity: 0.9,
  clearcoat: 1,
  color: "#757575",
  // transparent: true,
  // transmission: .95,
  opacity: 1,
  reflectivity: 0.2,
  refractionRatio: 0.15,
  ior: 0.9,
  side: THREE.BackSide,
});
const durationRatio = 1;
export const Door = ({
  glassArgs,
  windowArgs,
  frmaeArgs,
  glassPosition,
  frameGen,
  ...props
}) => {
  const doorOneRef = React.useRef();
  const doorTwoRef = React.useRef();

  useFrame(() => {
    // doorOneRef.current.rotation.y += 0.05;
    // doorTwoRef.current.rotation.y += -0.05;
  });
  const RenderWindowFrame = ({ args, ...props }) => {
    return (
      <mesh {...props} castShadow>
        <boxBufferGeometry args={args} />
        <meshPhysicalMaterial color="#8a807d" metalness={1} roughness={0.3} />
      </mesh>
    );
  };
  const frames = frameGen || FramesArray;

  const exit = useStore((state) => state.exit);

  const toggleExit = useStore((state) => state.actions.toggleExit);

  const [{ scale }, api] = useSpring(() => ({
    scale: 1,
    opacity: 0,

    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const [scroll, setScroll] = useSpring(() => ({
    reverse: false,
    loop: false,
    to: async (next, cancel) => {
      await next({ rotate1: angle * 3, rotate2: angle, delay: 5000 });
      await next({ rotate1: angle * 2, rotate2: angle * 2, delay: 2000 });
    },

    from: { rotate1: angle * 2, rotate2: angle * 2, delay: 2000 },
    delay: 3000,
    config: {
      duration: 3000,
    },
  }));

  const { rotate1, rotate2 } = scroll;

  const onMove = React.useCallback((e) => api({ scale: 1.05 }), []);
  const onOut = React.useCallback(() => api({ scale: 1 }), []);
  const onClick = React.useCallback(() => toggleExit(true), []);
  const handleClick = React.useCallback((e) => {
    e.stopPropagation();
    onClick();
  }, []);

  React.useEffect(() => {
    if (exit) {
      setScroll({
        to: async (next, cancel) => {
          await next({ rotate1: angle * 3, rotate2: angle, delay: 0 });
          await next({ rotate1: angle * 2, rotate2: angle * 2, delay: 2000 });
        },
      });
    }
  }, [exit]);
  return (
    <>
      <animated.group
        position={[0, 0, 0]}
        {...props}
        onClick={handleClick}
        onPointerOver={onMove}
        onPointerOut={onOut}
      >
        <ExitSign
          rotation={[0, angle, 0]}
          scale={0.05}
          position={[1, 17, 5.5]}
        />
        <Html
          as="div" // Wrapping element (default: 'div')
          // Project content behind the canvas (default: false)
          center
          distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
          zIndexRange={[1, 0]} // Z-order range (default=[16777271, 0])
          transform // If true, applies matrix3d transformations (default=false)
          sprite // Renders as sprite, but only in transform mode (default=false)
        >
          <a.div
            className="infoMainContainer"
            style={{
              opacity: scale.to({ range: [1, 1.05], output: [0, 1] }),

              transform: `translate(-200px,-100px)`,
            }}
          >
            <h2>Click on the door to exit</h2>
          </a.div>
        </Html>
        {frames.map((i, j) => (
          <RenderFrame key={j * 1 * 1} {...i} args={frmaeArgs || i.args} />
        ))}
        <group>
          {windowFrames.map((i, j) => (
            <RenderWindowFrame
              key={j * 1 * 1}
              {...i}
              args={frmaeArgs || i.args}
            />
          ))}
          <mesh
            rotation={[0, angle, 0]}
            position={glassPosition || [0, 21.5, 5.7]}
            material={glassMat}
          >
            <boxBufferGeometry args={windowArgs} />
          </mesh>
        </group>
        <animated.group
          ref={doorOneRef}
          position={[0, 8, -2.15]}
          rotation={[0, angle * 2, 0]}
          rotation-y={rotate1}
        >
          <mesh
            rotation={[0, angle, 0]}
            position={[0, 0, -4]}
            material={glassMat}
          >
            <boxBufferGeometry args={glassArgs} />
          </mesh>
          <LogoImage
            rotation={[0, angle, 0]}
            scale={0.05}
            position={[0.15, 2, -4]}
          />
        </animated.group>
        <animated.group
          ref={doorTwoRef}
          position={[0, 8, 13.55]}
          rotation={[0, -angle * 2, 0]}
          rotation-y={rotate2}
        >
          <mesh
            rotation={[0, angle, 0]}
            position={[0, 0, 4]}
            material={glassMat}
          >
            <boxBufferGeometry args={glassArgs} />
          </mesh>
          <TextGeo
            rotation={[0, angle, 0]}
            scale={0.15}
            position={[0.15, 2, 4]}
          />
        </animated.group>
      </animated.group>
    </>
  );
};

const Doors = () => {
  return (
    <group>
      <Door
        position={[-40, 0, -5.7]}
        glassArgs={[7.6, 14.7, 0.2]}
        windowArgs={[14.7, 12, 0.2]}
      />
    </group>
  );
};

export default Doors;
