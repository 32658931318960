import ProgressBar from "@ramonak/react-progress-bar";
import { a, useTransition } from "@react-spring/web";
import { useProgress } from "@react-three/drei";
import React from "react";
import useGetArtistName from "../hooks/useGetArtistName";

function Loader() {
  const prog = useProgress();
  const { active, progress } = prog;
  const transition = useTransition(active, {
    from: { opacity: 1, progress: 0 },
    leave: { opacity: 0 },
    update: { progress },
    delay: 0,
  });

  // console.log("useProgress", prog);
  const artist = useGetArtistName();
  return transition(
    ({ opacity }, active) =>
      active && (
        <a.div
          className="loading"
          style={{
            opacity: opacity,
          }}
        >
          <img
            src="logowhite.png"
            style={{
              height: "20%",
              width: "20%",
              objectFit: "contain",
              marginBottom: 20,
            }}
          />
          <div
            style={{
              color: "white",
              fontFamily: "Roboto",
              fontWeight: 500,
              marginBottom: 50,
            }}
          >
            {artist ? (
              <span>
                Loading gallery of{" "}
                <span style={{ textTransform: "capitalize" }}> {artist} </span>
              </span>
            ) : (
              "Hang on! Loading gallery"
            )}
          </div>
          <div style={{ width: "50%" }}>
            <ProgressBar
              completed={progress}
              isLabelVisible={false}
              bgColor="#fff"
              width="100%"
              baseBgColor="#5c5756"
            />
          </div>
        </a.div>
        // <a.div className="loading" style={{ opacity }}>
        //   <div className="loading-bar-container">
        //     <a.div className="loading-bar" style={{ width: progress }}>
        //       <a.div className="loading-data">
        //         {progress.to((p) => `${p.toFixed(2)}%`)}
        //       </a.div>
        //     </a.div>
        //   </div>
        // </a.div>
      )
  );

  // (

  // );
}

export default Loader;
